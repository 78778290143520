import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import cn from 'classnames';
import "./GoBack.scss";
import { useLocalizer } from "../../../hooks";

type GoBackProps = {
    text?: string;
    showAlways?: boolean;
    to?: string;
}

export const GoBack: FC<GoBackProps> = (props) => {
    const navigate = useNavigate();
    const { l } = useLocalizer('Global')

    const clicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (props.to === undefined) {
            event.preventDefault();
            navigate(-1);

            return;
        }
    }

    return (
        <div className={cn(['GoBack', {always: props.showAlways}])}>
            <Link to={props.to ?? '#'} onClick={clicked}>
                <i className="arrow left" />
                {props.text ?? l('goBackLabel')}
            </Link>
        </div>
    );
}
