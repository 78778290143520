import { LocalizedString } from '../../utils/localizer';

export const grantDetails: LocalizedString = {
  // Title

  grantTitle: {
    en: 'Grant',
    nb: 'Tilskudd',
    nn: 'Tilskot',
  },
  grantSubTitle: {
    en: 'Reference',
    nb: 'Referanse',
    nn: 'Referanse',
  },

  // For detailsView

  grantHeader: {
    en: 'Grant',
    nb: 'Tilskudd',
    nn: 'Tilskot',
  },
  availableAmount: {
    en: 'Available amount:',
    nb: 'Tilgjengelig beløp:',
    nn: 'Tilgjengeleg beløp:',
  },
  grantNotAvailableFooter: {
    en: 'The grant is disbursed.',
    nb: 'Tilskuddet er utbetalt.',
    nn: 'Tilskotet er utbetalt.',
  },
  availableFooter: {
    en: 'The grant is disbursed according to current agreement.',
    nb: 'Tilskuddet utbetales etter gjeldende avtale.',
    nn: 'Tilskotet vert utbetalt etter gjeldande avtale.',
  },
  warningFooter: {
    en: 'Applications approved prior to 1 January 2018 are under normal circumstances not available for disbursement.',
    nb: 'Tilsagn som er innvilget før 1. januar 2018 vil normalt ikke kunne utbetales lenger.',
    nn: 'Tilsagn som er innvilga før 1. januar 2018 vil vanlegvis ikkje bli utbetalt.',
  },
  payoutDeadline: {
    en: 'Last date for disbursement:',
    nb: 'Utbetalingsfrist:',
    nn: 'Utbetalingsfrist:',
  },
  payoutDeadlineFooter: {
    en: 'This is the deadline for disbursement of grant.',
    nb: 'Dette er siste frist for utbetaling av tilskuddet.',
    nn: 'Dette er siste frist for utbetaling av tilskotet.',
  },
  expiryDate: {
    en: 'Valid until:',
    nb: 'Gyldig til:',
    nn: 'Gyldig til:',
  },
  expiryDateFooter: {
    en: 'This is the final deadline for disbursement of the grant. The agreement terms must be met in order for the grant to be made available for use. Check the offer letter for terms and conditions.',
    nb: 'Dette er siste frist for å benytte tilskuddet. For å benytte tilskuddet må avtalevilkårene oppfylles. Se tilbudsbrev for detaljer om dine vilkår.',
    nn: 'Dette er siste frist for å nytte tilskotet. For å nytte tilskotet må vilkåra i avtalen vere oppfylte. Sjå tilbodsbrev for detaljar om vilkåra dine.',
  },
  availableViewMessage: {
    en: 'All amounts are in Norwegian kroner.',
    nb: 'Alle beløp er i norske kroner.',
    nn: 'Alle beløp er i norske kroner.',
  },

  // For validityView

  validityHeader: {
    en: 'Validity',
    nb: 'Gyldighet',
    nn: 'Gyldigheit',
  },
  approveDate: {
    en: 'Date granted:',
    nb: 'Innvilget dato:',
    nn: 'Innvilga dato:',
  },
  originalAmount: {
    en: 'Amount granted:',
    nb: 'Innvilget beløp:',
    nn: 'Innvilga beløp:',
  },
  bookBalance: {
    en: 'Amount disbursed:',
    nb: 'Utbetalt beløp:',
    nn: 'Utbetalt beløp:',
  },
  annullment: {
    en: 'Amount annulled:',
    nb: 'Annullert beløp:',
    nn: 'Annullert beløp:',
  },
  blockedAmount: {
    en: 'Amount annulled:',
    nb: 'Annullert beløp:',
    nn: 'Annullert beløp:',
  },

  // code

  grantInactiveWarning: {
    en: 'This grant is fully paid and/or annulled.',
    nb: 'Denne avtalen er utbetalt og/eller annullert.',
    nn: 'Denne avtalen er utbetalt og/eller annullert.',
  },
  transactionOverviewTitle: {
    en: 'Recent transactions',
    nb: 'Siste bevegelser',
    nn: 'Siste bevegelser',
  },

  // For debt collection

  debtCollectionWarningPart1: {
    en: 'Your grant has been sent for debt collection. Please get in touch with',
    nb: 'Ditt tilskudd er sendt til inkasso. Ta kontakt på',
    nn: 'Tilskotet ditt er sendt til inkasso. Ta kontakt på',
  },
  debtCollectionWarningPart2: {
    en: 'to resolve the situation.',
    nb: 'for å finne en løsning.',
    nn: 'for å finne ei løysing.',
  },
  solveDebtCollectionHeader: {
    en: 'How do I resolve the debt collection case?',
    nb: 'Hvordan løser jeg inkassosaken?',
    nn: 'Korleis løyser eg inkassosaka?',
  },
  solveDebtCollection: {
    en: 'The deadline for repayment of paid grants has not been complied with and the case has therefore been sent to debt collection. Contact the debt collection agency as soon as possible to find a solution. Keep the grant number ready when you contact them.',
    nb: 'Tidsfristen for tilbakebetaling av utbetalt tilskudd er ikke overholdt og saken er derfor sendt til inkasso. Kontakt inkassobyrået snarest for å finne en løsning. Ha tilskuddsnummeret klart når du kontakter dem.',
    nn: 'Tidsfristen for tilbakebetaling av utbetalt tilskot er ikkje overhalden og saka er derfor send til inkasso. Kontakt inkassobyrået snarast for å finna ei løysing. Ha tilskotsnummeret klart når du kontaktar dei.',
  },

  contactConecto: {
    en: 'Contact information',
    nb: 'Kontaktinformasjon',
    nn: 'Kontaktinformasjon',
  },
  contactKredinor: {
    en: 'Contact Kredinor',
    nb: 'Kontakt Kredinor',
    nn: 'Kontakt Kredinor',
  },
  webSiteLabel: {
    en: 'Web site:',
    nb: 'Nettside:',
    nn: 'Nettside:',
  },
  accountName: {
    en: 'Grant number:',
    nb: 'Tilskuddsnummer:',
    nn: 'Tilskotsnummer:',
  },

  // For Submitted Forms
  submittedFormsTitle: {
    en: 'Submitted on this account',
    nb: 'Innsendt på denne kontoen',
    nn: 'Innsendt på denne kontoen',
  },
};
