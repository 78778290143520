import { LocalizedString } from '../../utils/localizer';

export const transactionOverview: LocalizedString = {
  title: {
    en: 'Recent transactions',
    nb: 'Siste bevegelser',
    nn: 'Siste bevegelser',
  },
  transactionDate: {
    en: 'Date',
    nb: 'Dato',
    nn: 'Dato',
  },
  transactionCategory: {
    en: 'Type',
    nb: 'Type',
    nn: 'Type',
  },
  invoiceNumber: {
    en: 'Invoice',
    nb: 'Faktura',
    nn: 'Faktura',
  },
  amount: {
    en: 'Amount',
    nb: 'Beløp',
    nn: 'Beløp',
  },
  TransferGrant: {
    en: 'Grant disbursed',
    nb: 'Utbetalt tilskudd',
    nn: 'Utbetalt tilskot',
  },
  TransferLoan: {
    en: 'Loan disbursed',
    nb: 'Utbetalt lån',
    nn: 'Utbetalt lån',
  },
  CommisionPaymentInterestSubsidy: {
    nb: 'Utbetalt rentestøtte',
    nn: 'Utbetalt rentestønad',
    en: 'Interest subsidy disbursed'
  },
  CommisionRefundInterestSubsidy: {
    nb: 'Reversert rentestøtte',
    nn: 'Reversert rentestønad',
    en: 'Interest subsidy reversed'
  },
  PaymentGrant: {
    en: 'Repayment of grant',
    nb: 'Tilbakebetaling av tilskudd',
    nn: 'Tilbakebetaling av tilskot',
  },
  PaymentLoan: {
    en: 'Repayment of loan',
    nb: 'Betaling på lån',
    nn: 'Betaling på lån',
  },
  Other: {
    en: 'Other',
    nb: 'Annet',
    nn: 'Anna',
  },
  Blocking: {
    en: 'Annulled',
    nb: 'Annullert',
    nn: 'Annullert',
  },
  TransferredLoan: {
    en: 'Transferred loans',
    nb: 'Overført lån',
    nn: 'Overført lån',
  },
  // For download

  downloadLinkText: {
    en: 'Detailed transactions',
    nb: 'Detaljerte bevegelser',
    nn: 'Detaljerte bevegelser',
  },

  // for code

  noDataForGrantsMessage: {
    en: 'The grant has no transactions.',
    nb: 'Tilskuddet har ingen transaksjoner.',
    nn: 'Tilskotet har ingen transaksjonar.',
  },
  noDataForLoansMessage: {
    en: 'The loan has no transactions.',
    nb: 'Lånet har ingen transaksjoner.',
    nn: 'Lånet har ingen transaksjonar.',
  },
  noDataForInterestSubsidyMessage: {
    en: 'The interest subsidy has no transactions.',
    nb: 'Rentestøtten har ingen transaksjoner.',
    nn: 'Rentestønaden har ingen transaksjonar.',
  }

};
