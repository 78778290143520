import { apiDispatcher } from '../utils';
import { ApplicationDto, CustomerTaskDto, TaskDto } from '../models';

const baseUrl = '/api/v1/applications';

export const getApplication = (applicationId: string): Promise<ApplicationDto> => {
  return apiDispatcher<ApplicationDto>(`${baseUrl}/${applicationId}`, 'GET');
}

export const getApplicationCustomerTasks = (applicationId: string): Promise<Array<CustomerTaskDto>> => {
  return apiDispatcher<Array<CustomerTaskDto>>(`${baseUrl}/${applicationId}/customer-tasks`, 'GET');
}

export const getTask = (applicationId: string, taskId: string): Promise<TaskDto> => {
  return apiDispatcher<TaskDto>(`${baseUrl}/${applicationId}/tasks/${taskId}`, 'GET');
}
