import { useNumberUtils } from './useNumberUtils';
import { useDateUtils } from './useDateUtils';
import { DateTime } from 'luxon';
 
interface Formatter {
  [key: string]: (value: any) => any;
}

export const useFormValueFormatter = () => {
  const numberUtils = useNumberUtils();
  const { toFormattedCoreViewDate } = useDateUtils();

  return {
    text: (value: any) => {
      return value;
    },
    number: (value: any) => {
      return value;
    },
    decimal: (value: any) => {
      return value;
    },
    amount: (value: any) => {
      return value !== null ? numberUtils.toFixedDisplayValue(value, 2) : value;
    },
    date: (value: any) => {
      return value !== undefined && value !== null ? toFormattedCoreViewDate(value, DateTime.DATE_SHORT) : value;
    }
  } as Formatter
}
