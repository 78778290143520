import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './styles/style.scss';

// Tailwind injection
import './tailwind.css';

import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from './context/app-context';
import { LocalizationProvider } from './context/localization-context';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

const container = document.getElementById('root');
if (container !== null) {
  const root = createRoot(container);
  root.render(
    <AppProvider>
      <StrictMode>
        <LocalizationProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </StrictMode>
    </AppProvider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
