import memoized from 'memoize-one';
import { apiDispatcher } from '../utils';
import { DocumentDto } from '../models/digibank-mypage.models';
import saveAs from 'file-saver';

const baseUrl = '/api/v1/documents';

export const getDocuments = memoized((context?: string) => {
  const searchParams = new URLSearchParams();
  if (context)
    searchParams.append('context', context);

  return apiDispatcher<Array<DocumentDto>>(`${baseUrl}?${searchParams.toString()}`, "GET");
});

export const getDeferralDocument = memoized((context?: string) => {
  const searchParams = new URLSearchParams("documentTypes=BankingDeferralPaymentDocument");
  if (context)
    searchParams.append('context', context);

  return apiDispatcher<Array<DocumentDto>>(`${baseUrl}?${searchParams.toString()}`, "GET");  
});

export const downloadDocument = async (id: string) => {
  apiDispatcher(`${baseUrl}/${id}/DocumentEvent`, "POST" );
  return apiDispatcher<Blob>(`${baseUrl}/${id}/download`, "GET", undefined, { resolver: resp => resp.blob() })
}

export const saveDocument = async (document: DocumentDto) => {
  const blob = await downloadDocument(document.id as string);
  saveAs(blob, document.name);
}
