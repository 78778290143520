import { LocalizedString } from '../../utils/localizer';

export const formAction: LocalizedString = {
  NoneOpusRedirect: {
    nb: 'Klikk her for detaljer og utbetaling',
    nn: 'Klikk her for detaljar og utbetaling',
    en: 'Click here for details and payout'
  },
  PayoutRequestGrantOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestGrantCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestGrantPayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestGrantPayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestGrantPayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestGrantPayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  PayoutRequestGrantConferenceAttendanceOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestGrantConferenceAttendanceCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestGrantConferenceAttendancePayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestGrantConferenceAttendancePayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestGrantConferenceAttendancePayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestGrantConferenceAttendancePayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },  
  PayoutRequestGrantMatilOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestGrantMatilCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestGrantMatilPayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestGrantMatilPayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestGrantMatilPayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestGrantMatilPayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  PayoutRequestGrantOpusMatilOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestGrantOpusMatilCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestGrantOpusMatilPayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestGrantOpusMatilPayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestGrantOpusMatilPayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestGrantOpusMatilPayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  PayoutRequestGrantOpusBioeOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestGrantOpusBioeCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestGrantOpusBioePayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestGrantOpusBioePayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestGrantOpusBioePayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestGrantOpusBioePayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  PayoutRequestGrantOpusTrlOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestGrantOpusTrlCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestGrantOpusTrlPayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestGrantOpusTrlPayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestGrantOpusTrlPayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestGrantOpusTrlPayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  PayoutRequestGrantOpusPayoutUnavailableTemp: {
    nb: "Utbetaling er midlertidig utilgjengelig",
    nn: "Utbetaling er midlertidig utilgjengelig",
    en: "Payout is temporarily not available"
  },
  FinalReportGrantCreateForm: {
    nb: 'Start sluttrapportering',
    nn: 'Start sluttrapportering',
    en: 'Start final report'
  },
  FinalReportGrantOpenForm: {
    nb: 'Fortsett sluttrapportering',
    nn: 'Hald fram sluttrapportering',
    en: 'Continue final report'
  },
  FinalReportGrantFinalReportSent :{
    nb: 'Sluttrapportering er innsendt',
    nn: 'Sluttrapportering er innsend',
    en: 'Final report has been sent '
  },
  FinalReportGrantFinalReportUnavailable :{
    nb: 'Sluttrapportering er deaktivert',
    nn: 'Sluttrapportering er deaktivert',
    en: 'Final report has been deactivated'
  },
  PayoutRequestStartupLoanOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestStartupLoanCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestStartupLoanPayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestStartupLoanPayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestStartupLoanPayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestStartupLoanPayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  PayoutRequestLoanOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestLoanCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestLoanPayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestLoanPayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestLoanPayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestLoanPayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  PayoutRequestLoanShippingFishingOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestLoanShippingFishingCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestLoanShippingFishingPayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestLoanShippingFishingPayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestLoanShippingFishingPayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestLoanShippingFishingPayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  PayoutRequestGrantHIPOpenForm: {
    nb: 'Fortsett utbetaling',
    nn: 'Fortsett utbetaling',
    en: 'Continue payout'
  },
  PayoutRequestGrantHIPCreateForm: {
    nb: 'Start utbetaling',
    nn: 'Start utbetaling',
    en: 'Start payout'
  },
  PayoutRequestGrantHIPPayoutPaidOut: {
    nb: "Utbetalt",
    nn: "Utbetalt",
    en: "Paid out"
  },
  PayoutRequestGrantHIPPayoutDeadlinePassed: {
    nb: "Utbetalingsfristen er passert",
    nn: "Utbetalingsfristen er passert",
    en: "The payout deadline has passed"
  },
  PayoutRequestGrantHIPPayoutUnavailable: {
    nb: "Utbetaling er ikke tilgjengelig",
    nn: "Utbetaling er ikkje tilgjengeleg",
    en: "Payout is not available"
  },
  PayoutRequestGrantHIPPayoutSent: {
    nb: "Utbetalingsanmodning er innsendt",
    nn: "Utbetalingsanmodning er innsend",
    en: "Payment request has been sent"
  },
  CreateForm : {
    nb: "Her kan du be om utbetaling i henhold til tilbudsbrev.",
    nn: "Her kan du be om utbetaling i samsvar med tilbodsbrev.",
    en: "Here you can request payout according to the offer letter."
  },
  OpenForm : {
    nb: "Her kan du fortsette på utbetaling.",
    nn: "Her kan du fortsette på utbetaling.",
    en: "Here you can continue with payout."
  },
  PayoutPaidOut : {
    nb: "Utbetaling er fullført.",
    nn: "Utbetaling er fullført.",
    en: "The payout is completed."
  },
  PayoutDeadlinePassed : {
    nb: "Det er ikke lenger mulig å be om utbetaling siden utbetalingsfristen er passert. Ta kontakt med din saksbehandler.",
    nn: "Det er ikkje lenger mogleg å be om utbetaling sidan utbetalingsfristen er passert. Ta kontakt med saksbehandlaren din.",
    en: "It is no longer possible to request a payout since the payment deadline has passed. Contact your case manager."
  },
  PayoutUnavailable : {
    nb: "Det kan være ulike grunner til at du ikke kan be om utbetaling. Ta kontakt med din saksbehandler.",
    nn: "Det kan vera ulike grunner til at du ikkje kan be om utbetaling. Ta kontakt med saksbehandlaren din.",
    en: "There may be various reasons why you cannot request a payout. Contact your case manager."
  },
  PayoutSent : {
    nb: "Utbetalingen er under behandling. Om det er sluttutbetaling og dine særvilkår krever en sluttrapport, avventer Innovasjon Norge utbetaling til sluttrapporten er innsendt.",
    nn: "Utbetalingen er under behandling. Om det er sluttutbetaling og dine særvilkår krev ein sluttrapport, ventar Innnovasjon Noreg med utbetaling til sluttrapporten er innsend.",
    en: "The payout is being processed. If it is a final payment and your special conditions require a final report, Innovation Norway will hold payout until the final report is submitted."
  },
  ProjectAccountingHelpCreateForm : {
    nb: "Fyll ut og send inn prosjektregnskapet.",
    nn: "Fyll ut og send inn prosjektrekneskapet.",
    en: "Fill in the form for project accounting."
  },
  ProjectAccountingCreateForm : {
    nb: "Send inn prosjektregnskap",
    nn: "Send inn prosjektrekneskap",
    en: "Send in the project accounting"
  },
  ProjectAccountingHelpOpenForm : {
    nb: "Fortsett med å fylle ut prosjektregnskapet.",
    nn: "Fortsett med å fylla ut prosjektrekneskapet.",
    en: "Continue with the project accounting."
  },
  ProjectAccountingOpenForm : {
    nb: "Fortsett med prosjektregnskapet",
    nn: "Fortsett prosjektrekneskapet",
    en: "Continue the project accounting"
  }
}
