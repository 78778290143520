import { LocalizedString } from '../../utils/localizer';

export const submittedForm: LocalizedString = {
  dateHeaderLabel: {
    en: 'Submitted',
    nb: 'Sendt inn',
    nn: 'Sendt inn'
  },
  titleHeaderLabel: {
    en: 'Document',
    nb: 'Dokument',
    nn: 'Dokument'
  },
  detailsHeaderLabel: {
    en: 'Show',
    nb: 'Vis',
    nn: 'Vis'
  },
  submittedDateLabel: {
    en: 'Submitted',
    nb: 'Sendt inn',
    nn: 'Sendt inn'
  },
  confirmationsLabel: {
    en: 'Confirmations',
    nb: 'Bekreftelser',
    nn: 'Bekreftelsar'
  },
  submittedByLabel: {
    en: 'Submitted by',
    nb: 'Innsender',
    nn: 'Innsender'
  },
  deferralPaymentLabel: {
    en: "Submitted invoice number",
    nb: "Innsendt fakturanummer",
    nn: "Innsendt fakturanummer",
  }
}