import { LocalizedString } from '../../utils/localizer';

export const form: LocalizedString = {
  errorTitle: {
    nb: 'Beklager, noe gikk galt',
    nn: 'Beklager, ein feil oppstod',
    en: 'Sorry, something went wrong'
  },
  errorBody: {
    nb: 'Vennligst prøv på nytt. Om problemet vedvarer, beskriv hva som feiler og meld fra til <a href="mailto:kundesupport@innovasjonnorge.no">kundesupport@innovasjonnorge.no</a>',
    nn: 'Ver venleg og prøv på nytt. Om problemet held fram, beskriv kva som feiler og meld frå til <a href="mailto:kundesupport@innovasjonnorge.no">kundesupport@innovasjonnorge.no</a>',
    en: 'Please try again. If the problem still occurs, describe the issue and report to <a href="mailto:kundesupport@innovasjonnorge.no">kundesupport@innovasjonnorge.no</a>'
  },
  errorBodyNoRole: {
    nb: 'Bare personer som har en offisiell rolle, registrert i Brønnøysundregistrene, kan sende inn søknaden på vegne av bedriften. For mer informasjon om vår rollestyring, se vår <a href="https://www.innovasjonnorge.no/no/logg-inn-subsite/kan-vi-hjelpe-deg/" target="_blank" rel="noopener noreferrer">FAQ for rollestyring</a>.',
    nn: 'Berre personar med ein offisiell rolle, registrert i Brønnøysundregistrene, kan senda inn søknaden på vegne av bedriften. For meir informasjon om vår rollestyring, sjå vår <a href="https://www.innovasjonnorge.no/no/logg-inn-subsite/kan-vi-hjelpe-deg/" target="_blank" rel="noopener noreferrer">FAQ for rollestyring</a>.',
    en: 'Only persons with an official role, registered in Brønnøysundregistrene, can send an application on behalf of the company. For more information about our role management, see our <a href="https://www.innovasjonnorge.no/no/logg-inn-subsite/kan-vi-hjelpe-deg/" target="_blank" rel="noopener noreferrer">FAQ for role management</a>.'
  },
  errorCloseLabel: {
    nb: 'Tilbake',
    nn: 'Tilbake',
    en: 'Back'
  },
  pageValidationErrorTitle: {
    nb: 'Skjemaet kan ikke sendes inn.',
    nn: 'Skjemaet kan ikkje sendast inn.',
    en: 'The form cannot be submitted.'
  },
  formPageValidationSummaryTitle: {
    nb: 'Skjemaet kan ikke sendes inn på grunn av:',
    nn: 'Skjemaet kan ikkje sendast inn på grunn av:',
    en: 'The form cannot be submitted due to:'
  },
  noConfirmationChosenMessage: {
    nb: 'Vennligst bekreft før innsending.',
    nn: 'Ver venleg og stadfest før innsending.',
    en: 'Please confirm before submission.'
  },
  jumpToFieldAndCorrect: {
	  en: 'Click to correct field for:',
	  nb: 'Klikk for å rette felt for:',
	  nn: 'Klikk for å rette felt for:',
  },
  accessibilityNavBarLabel: {
	  en: 'Page {p} of {n}.',
	  nb: 'Side {p} av {n}.',
	  nn: 'Side {p} av {n}.',
  },
  accessibilityNavBarErrorOnPageLabel: {
	  en: 'This page is not filled in correctly.',
	  nb: 'Denne siden er ikke utfylt riktig.',
	  nn: 'Denne siden er ikke utfylt riktig.',
  },
  fileUploadFailedLabel: {
    en: 'Sorry, something went wrong',
    nb: 'Beklager, noe gikk galt',
    nn: 'Beklager, noe gikk galt'
  },
  fileDownloadFailedlabel: {
    en: "The form must be valid before download of document",
    nb: "Skjema må være validert før du kan laste ned dokument",
    nn: "Skjema må være validert før du kan laste ned dokument",
  },
  errorGeneratingSigningDocument: {
    en: "Could not create signing document",
    nb: "Kunne ikke opprette signeringsdukument",
    nn: "Kunne ikkje oppretta signeringsdukument",
  },
  fileUploadTypeReminder: {
    en: "You can upload the following file types: .pdf, .docx, .xlsx, .odt, and .ods",
    nb: "Du kan laste opp følgende filtyper: .pdf, .docx, .xlsx, .odt og .ods",
    nn: "Du kan laste opp følgjande filtypar: .pdf, .docx, .xlsx, .odt og .ods",
  }
}
