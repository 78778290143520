import React, { FC, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useLocalizer } from '../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs as Bc, BreadcrumbsProps } from '@in/component-library';
import { AppContext } from '../context/app-context';

import './Breadcrumbs.scss';
import classNames from 'classnames';

export const Breadcrumbs: FC = () => {
  const location = useLocation();
  const [breadcrumbsProps, setBreadcrumbsProps] = useState<BreadcrumbsProps>({ links: [{ name: 'root', href: '/' }] });

  const { state } = useContext(AppContext);

  const { l } = useLocalizer('BreadCrumbs');

  const navigate = useNavigate();

  useEffect(() => {
    const sanitizeBreadcrumbs = (path: string): string[] => {
      const breadcrumbs = path.split('/');
      const sanitizedBreadcrumbs: string[] = [];

      for (const segment of breadcrumbs) {
        const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(segment);
        const isCombinedUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}[%7C|]*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(segment);
        const isNumericId = /^[\d\.-]+$/.test(segment);

        if (isUUID || isCombinedUUID || isNumericId) continue;
        sanitizedBreadcrumbs.push(segment);
      }

      return sanitizedBreadcrumbs;
    };

    const removeBreadcrumbs = (crumbs: string[]): string[] => {
      return crumbs.filter((cr) => cr !== 'details' && cr !== '');
    };

    const addHomeBreadcrumb = (crumbs: string[]): string[] => {
      if (crumbs.length !== 1) return crumbs;
      switch (crumbs[0]) {
        case 'applications':
        case 'casemanagement':
        case 'documents':
          return ['bankaccounts'].concat(crumbs);
      }
      return crumbs;
    };

    if (location.pathname && location.pathname.startsWith('/')) {
      setBreadcrumbsProps(createCrumbProps(addHomeBreadcrumb(removeBreadcrumbs(sanitizeBreadcrumbs(location.pathname)))));
    }
  }, [location.pathname, state.configuration]);

  const createCrumbProps = (breadcrumbs: string[]) => {
    if (!breadcrumbs || breadcrumbs.length == 0 || (breadcrumbs[0] == 'forms' && (breadcrumbs[1] == 'edit' || breadcrumbs[1] == 'application'))) return { links: [{ name: 'hidden', href: '/' }] };
    const links = breadcrumbs.map((bc, i) => {
      return {
        name: l(bc),
        target: '_self',
        onClick: (e: SyntheticEvent) => {
          if (i === 0 && breadcrumbs.length > 0) {
            // Only first level needs to be clickable atm
            navigate(`/${bc}`);
          }
          e.preventDefault();
        },
      };
    });
    return { links: links, className: classNames('breadcrumbs', { fixRootPage: breadcrumbs.length === 1 }) } as BreadcrumbsProps;
  };

  if (!(breadcrumbsProps?.links?.length > 0) || breadcrumbsProps.links[0].name === 'hidden') return <></>;

  return <Bc {...breadcrumbsProps}></Bc>
};
