import React, { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getApplication, getApplicationCustomerTasks } from '../../services/applications.service';
import { ApplicationDto, CustomerTaskDto, DocumentDto, FormActionDto, FormActionTypeEnum, FormApplicationTypeEnum } from '../../models';
import { ApplicationCard } from '../../components/ApplicationCard/ApplicationCard';
import { Link } from '@in/component-library';
import { getDocuments, saveDocument } from '../../services/documents.service';
import { DocumentList } from '../../components/DocumentList/DocumentList';
import { CustomerTaskList } from '../../components/CustomerTaskList/CustomerTaskList';
import { useCustomerTaskClickHandler, useLocalizer } from '../../hooks';
import { useFormActionHandler } from '../../hooks/useFormActionHandler';
import { useEnvironment } from '../../hooks/useEnvironment';
import { useFeatures } from '../../hooks';
import './ApplicationDetailsController.scss';
import { TabDefinition, Tabs } from '../../components/Shared/Tabs/Tabs';

type ApplicationDetailsDataParams = {
  applicationId: string;
};

export const ApplicationDetailsData: FC = () => {
  const [tasks, setTasks] = React.useState<Array<CustomerTaskDto>>([]);
  const [tasksLoaded, setTasksLoaded] = React.useState<boolean>(false);
  const [application, setApplication] = React.useState<ApplicationDto>({} as ApplicationDto);
  const [documents, setDocuments] = React.useState<Array<DocumentDto>>([]);
  const [documentsLoaded, setDocumentsLoaded] = React.useState<boolean>(false);
  const [applicationFormAction, setApplicationFormAction] = React.useState<FormActionDto>({} as FormActionDto);

  const { applicationId } = useParams() as ApplicationDetailsDataParams;

  const [searchParams, setSearchParams] = useSearchParams({ tab: 'tasks' });
  const tab = searchParams.get('tab');

  const taskClickHandler = useCustomerTaskClickHandler();

  const { l } = useLocalizer('ApplicationDetails');
  const formActionHandler = useFormActionHandler();
  const { environmentInitialised, getCaseHelpBaseUrl } = useEnvironment();
  const { hasFeature } = useFeatures();
  const [tabs, setTabs] = useState<TabDefinition[]>([]);

  const tabTypes = ['tasks', 'documents', 'history'];

  useEffect(() => {
    const tabDefinition: TabDefinition[] = [
      {
        id: 0,
        label: `${l('taskTabLabel')} (${tasks.filter((t) => t.active).length})`,
        title: `${l('taskTabLabel')}`,
      },
      {
        id: 1,
        label: `${l('documentTabLabel')} (${documents.length})`,
        title: l('documentTabLabel'),
      },
    ];
    function addHistory() {
      tabDefinition.push({
        id: 2,
        label: `${l('historyTabLabel')} (1)`,
        title: `${l('historyTabLabel')}`,
      });
    }

    if (environmentInitialised) {
      if (hasFeature('LinkToCaseHelp')) {
        addHistory();
      }
    }
    if (environmentInitialised && tasks && documents) {
      setTabs(tabDefinition);
    }
  }, [environmentInitialised, tasks, documents]);

  const handleTabOnSelect = (n: number): void => {
    goToSubPage(tabTypes[n]);
  };

  useEffect(() => {
    getApplication(applicationId).then((app) => setApplication(app)).catch(()=>{console.error(`Could not load application with appId = ${applicationId}`)});
    getApplicationCustomerTasks(applicationId)
      .then((tasks) => setTasks(tasks))
      .finally(() => setTasksLoaded(true));
    getDocuments(applicationId)
      .then(setDocuments)
      .finally(() => setDocumentsLoaded(true));
    return () => {
      setApplication({} as ApplicationDto);
      setTasks([]);
      setDocuments([]);
    };
  }, [applicationId]);

  const goToSubPage = (page?: string) => {
    if (page === undefined) setSearchParams({}, { replace: true });
    else setSearchParams({ tab: page }, { replace: true });
  };

  useEffect(() => {
    if (application.id && application.customerAccountId && environmentInitialised) {
      setApplicationFormAction({
        data: getCaseHelpBaseUrl() + application.id + '|' + application.customerAccountId,
        applicationType: FormApplicationTypeEnum.None,
        actionType: FormActionTypeEnum.CaseRedirect,
      } as FormActionDto);
    }
  }, [application, environmentInitialised]);

  return (
    <div className="applicationDetails">
      <ApplicationCard application={application} />
      <Tabs
        aria-label={l('menuAriaLabel')}
        tabs={tabs}
        handleTabOnSelect={handleTabOnSelect}
        defaultSelection={0}
        overrideSelection={tab !== null ? tabTypes.indexOf(tab) : undefined}
      ></Tabs>
      {tab === 'history' && (
        <div>
          <div className="heading">
            <span className="number">&nbsp;&nbsp;1&nbsp;&nbsp;</span>&nbsp;{l('historyTitle')}
          </div>
          <div className="yourCase">
            <div className="textBox">
              <p className="text">{l('linkToCaseLeadText')}</p>
            </div>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.pushState({}, document.title, window.location.href);
                formActionHandler(applicationFormAction);
              }}
              iconName="fal fa-arrow-right"
              iconPosition="right"
              hideUnderline={false}
            >
              {l('linkToCaseLinkText')}
            </Link>
          </div>
        </div>
      )}
      {tab === 'tasks' && <CustomerTaskList tasks={tasks} onTaskClick={taskClickHandler} isLoaded={tasksLoaded} />}
      {tab === 'documents' && <DocumentList documents={documents} onDownload={saveDocument} isLoaded={documentsLoaded} />}
    </div>
  );
};
