import { useContext } from 'react';
import { AppContext } from '../context/app-context';
import { getFixedDisplayValue, addThousandSeparators, amountExists } from '../utils/numbers';

const useNumberUtils = () => {
  const { state } = useContext(AppContext);

  const toFixedDisplayValue = (value: any, numberOfDecimals: number): string | undefined => {
    return getFixedDisplayValue(value, numberOfDecimals, state.configuration.locale?.decimalSeparator);
  };

  const withThousandSeparators = (value: any): string => {
    return addThousandSeparators(value);
  };

  const toAmount = (value: number, numberOfDecimals: number): string | undefined => {
    return toFixedDisplayValue(withThousandSeparators(value), numberOfDecimals);
  };

  const hasAvailableAmount = (amount: number | undefined): boolean => {
    return amountExists(amount);
  };

  return {
    hasAvailableAmount,
    toAmount,
    toFixedDisplayValue,
    withThousandSeparators,
  };
};

export { useNumberUtils };

// Retroactive types to prevent regressions. We cannot use interface due to existing code.
// TODO swap out with interfaces
type useNumberUtils = ReturnType<typeof useNumberUtils>;
export type toAmountFunction = useNumberUtils['toAmount'];
