import { FC, useContext } from 'react';
import { AppContext } from '../context/app-context';

export const Feature: FC<{ name: string; children?: React.ReactNode }> = ({ name, children }) => {
  const { state } = useContext(AppContext);

  const features = state.configuration.features;
  const feature = features.find((f) => f.name === name);

  if (!feature) console.warn('Feature flag not found: ', name);

  if (feature && feature.isActive) {
    return <>{children}</>;
  }
  return null;
};
