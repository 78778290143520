import { PageLoader } from '@in/component-library';
import { FC } from 'react';
import './LoadingSpinner.scss';
import { useLocalizer } from '../../../hooks';

export const LoadingSpinner: FC = () => {
  const { globalL } = useLocalizer('Global');

  return (
    <div className='pageLoaderWrapper'>
      <PageLoader className="loading" aria-label={globalL('loadingSpinner')} />
    </div>
  );};
