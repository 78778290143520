import { LocalizedString } from '../../utils/localizer';

export const generalApplications: LocalizedString = {
  formMaintenance: {
    en: 'Denne siden er under vedlikehold, eller stengt på ubestemt tid.',
    nb: 'Denne siden er under vedlikehold, eller stengt på ubestemt tid.',
    nn: 'Denne siden er under vedlikehold, eller stengt på ubestemt tid.',
  },
  caseNumber: {
    en: 'Reference',
    nb: 'Saksnummer',
    nn: 'Saksnummer',
  },
  caseNumberGeneralApplicationAdditionalInformationHelpText: {
    en: 'The reference number can be found in previous correspondance with Innovation Norway and has the format: YYYY/NNNNNN',
    nb: 'Saksnummeret finner du i tidligere korrespondanse med Innovasjon Norge og har formatet: YYYY/NNNNNN',
    nn: 'Saksnummeret finn du i tidlegare korrespondanse med Innovasjon Norge og har formatet: YYYY/NNNNNN',
  },
  caseNumberHelpText: {
    en: 'Saksnummer finner du i tilbudsbrevet og dokument for "Utbetalingsanmodning". Formatet må være: YYYY/NNNNNN',
    nb: 'Saksnummer finner du i tilbudsbrevet og dokument for "Utbetalingsanmodning". Formatet må være: YYYY/NNNNNN',
    nn: 'Saksnummer finner du i tilbudsbrevet og dokument for "Utbetalingsanmodning". Formatet må være: YYYY/NNNNNN',
  },
  accountName: {
    en: 'Account number',
    nb: 'Kontonummer (hos Innovasjon Norge)',
    nn: 'Kontonummer',
  },
  accountNameHelpText: {
    en: 'Kontonummer finner du i tilbudsbrevet og dokument for "Utbetalingsanmodning". Formatet må være: XXXX.XXXXX-X',
    nb: 'Kontonummer finner du i tilbudsbrevet og dokument for "Utbetalingsanmodning". Formatet må være: XXXX.XXXXX-X',
    nn: 'Kontonummer finner du i tilbudsbrevet og dokument for "Utbetalingsanmodning". Formatet må være: XXXX.XXXXX-X',
  },
  CoreviewMissingAccountNumber: {
    en: 'Missing account number',
    nb: 'Kontonummer fins ikke',
    nn: 'Kontonummer finst ikkje',
  },
  MismatchingCaseNumberAndAccountNumber: {
    en: 'Mismatching case number and account number',
    nb: 'Kombinasjonen; Saksnummer og kontonummer stemmer ikke',
    nn: 'Kombinasjonen; Saksnummer og kontonummer stemmer ikkje',
  },
  MustHaveRoleInBrreg: {
    en: 'To ask for payout one must have a role in BRREG.',
    nb: 'For å be om utbetaling må man ha en rolle i BREGG.',
    nn: 'For å be om utbetaling må ein ha ei rolle i BREGG.'
  },
  EmptyList: {
    en: 'There is no tasks available',
    nb: 'Ingen saker tilgjengelig',
    nn: 'Ingen tilgjengelege saker',
  },
  noSelectableOptions: {
    en: 'No selectable option',
    nb: 'Ingen mulige valg',
    nn: 'Ingen mulige val',
  },
  NotCorrectAccountName: {
    en: 'Kontonummer finnes ikke på saksnummeret du har valgt. Vennligst kontroller at du er innlogget på rett aktør og at formatet er korrekt.',
    nb: 'Kontonummer finnes ikke på saksnummeret du har valgt. Vennligst kontroller at du er innlogget på rett aktør og at formatet er korrekt.',
    nn: 'Kontonummer finnes ikke på saksnummeret du har valgt. Vennligst kontroller at du er innlogget på rett aktør og at formatet er korrekt.',
  },
  NotCorrectCaseNumber: {
    en: 'Saksnummer finnes ikke på selskapet du har valgt. Vennligst kontroller at du er innlogget på rett aktør og at formatet er korrekt.',
    nb: 'Saksnummer finnes ikke på selskapet du har valgt. Vennligst kontroller at du er innlogget på rett aktør og at formatet er korrekt.',
    nn: 'Saksnummer finnes ikke på selskapet du har valgt. Vennligst kontroller at du er innlogget på rett aktør og at formatet er korrekt.',
  },
  NotCorrectCompany: {
    en: 'Company mismatch with logged on identity',
    nb: 'Seleskap stemmer ikke med pålogget identitet',
    nn: 'Seleskap stemmer ikkje med pålogga identitet',
  },
  NotCorrectDecisionSystem: {
    en: 'It is not possible to use the service on this case. For help, contact your case handler.',
    nb: 'Det er ikke mulig benytte funksjonaliteten på denne saken. For hjelp, kontakt din saksbehandler.',
    nn: 'Det er ikkje mogleg å nytte funksjonaliteten på denne saka. For hjelp, kontakt din saksbehandlar.',
  },
  NotFilledIn: {
    en: 'Required field',
    nb: 'Påkrevd felt',
    nn: 'Påkrevd felt',
  },
  NotPossibleToAskForPayoutMessage: {
    en: 'Det er ikke mulig å be om utbetaling. Årsak kan være utløpt betalingsfrist. For hjelp, kontakt kundesupport@innovasjonnorge.no',
    nb: 'Det er ikke mulig å be om utbetaling. Årsak kan være utløpt betalingsfrist. For hjelp, kontakt kundesupport@innovasjonnorge.no',
    nn: 'Det er ikke mulig å be om utbetaling. Årsak kan være utløpt betalingsfrist. For hjelp, kontakt kundesupport@innovasjonnorge.no',
  },
  NotValidFormApplicationType: {
    en: 'Could not find a suitable form application type',
    nb: 'Ingen gyldig skjematype funnet',
    nn: 'Ingen gyldig skjematype funnet',
  },
  NotSelected: {
    en: 'Choose a task',
    nb: 'Velg et engasjement',
    nn: 'Velg eit engasjement',
  },
  ServiceError: {
    en: 'En feil har oppstått. Prøv igjen senere eller meld fra til support om det gjentar seg.',
    nb: 'En feil har oppstått. Prøv igjen senere eller meld fra til support om det gjentar seg.',
    nn: 'En feil har oppstått. Prøv igjen senere eller meld fra til support om det gjentar seg.',
  }
};
