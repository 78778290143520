import { DateTime, DateTimeJSOptions } from 'luxon';

export function formatCoreviewDate(coreViewDate: string, locale: string, formatOption?: Intl.DateTimeFormatOptions) {
  const coreviewDateOptions: DateTimeJSOptions = {
    locale: 'nb-NO',
    zone: 'utc',
  };
  const monthNamesNo = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

  function formatDate(dateTime: DateTime, locale: string) {
    if (locale.indexOf('NO') > -1) {
      return dateTime.day
        .toString()
        .concat('. ')
        .concat(monthNamesNo[dateTime.month - 1])
        .concat(' ')
        .concat(dateTime.year.toString());
    }
    dateTime = dateTime.reconfigure({ locale: locale });
    return dateTime.toLocaleString(DateTime.DATE_FULL);
  }

  let dateTime = DateTime.fromISO(coreViewDate, coreviewDateOptions);
  if (formatOption === DateTime.DATE_SHORT) {
    dateTime = dateTime.reconfigure({ locale: locale === 'nn-NO' ? 'nb-NO' : locale });
    return dateTime.toLocaleString(DateTime.DATE_SHORT).replace(/\d+/g, function (digits) {
      return '00'.substr(digits.length) + digits;
    });
  }
  return formatDate(dateTime, locale);
}

export function getCoreviewYear(coreViewDate: string, locale: string) {
  const coreviewDateOptions: DateTimeJSOptions = {
    locale: 'nb-NO',
    zone: 'utc',
  };
  let dateTime = DateTime.fromJSDate(new Date(coreViewDate), coreviewDateOptions);
  dateTime = dateTime.reconfigure({ locale: locale });
  return dateTime.year.toString();
}

export function formatDateString(date: Date|undefined|null) {
  return date !== null && date !== undefined
  ? `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T00:00:00.000Z`
  : null;
}
