import { LocalizedString } from '../../utils/localizer';

export const guaranteeDetails: LocalizedString = {
  // For balance view

  guaranteeTitle: {
    en: 'Guarantee',
    nb: 'Garanti',
    nn: 'Garanti',
  },
  backingGuaranteeTitle: {
    en: 'Guarantee for Growth',
    nb: 'Vekstgaranti',
    nn: 'Vekstgaranti',
  },
  guaranteeSubTitle: {
    en: 'Reference',
    nb: 'Referanse',
    nn: 'Referanse',
  },

  balanceHeader: {
    en: 'Guarantee',
    nb: 'Garanti',
    nn: 'Garanti',
  },
  counterGuaranteeBalanceHeader: {
    en: 'Guarantee for Growth',
    nb: 'Vekstgaranti',
    nn: 'Vekstgaranti',
  },
  bookBalance: {
    en: 'Amount guaranteed:',
    nb: 'Garantert beløp:',
    nn: 'Garantert beløp:',
  },
  bookBalanceFooter: {
    en: 'Awaiting payment of establishment fee and guarantee commission.',
    nb: 'Avventer innbetaling av etableringsgebyr og garantiprovisjon.',
    nn: 'Ventar på innbetaling av etableringsgebyr og garantiprovisjon.',
  },
  bookBalanceFooterLink: {
    en: 'https://www.innovasjonnorge.no/no/tjenester/innovasjon-og-utvikling/finansiering-for-innovasjon-og-utvikling/vekstgarantiordningen/',
    nb: 'https://www.innovasjonnorge.no/no/tjenester/innovasjon-og-utvikling/finansiering-for-innovasjon-og-utvikling/vekstgarantiordningen/',
    nn: 'https://www.innovasjonnorge.no/no/tjenester/innovasjon-og-utvikling/finansiering-for-innovasjon-og-utvikling/vekstgarantiordningen/',
  },
  bookBalanceFooterLinkPrefix: {
    en: 'Information about Guarantee for Growth:',
    nb: 'Informasjon om vekstgarantiordningen:',
    nn: 'Informasjon om vekstgarantiordninga:',
  },
  bookBalanceFooterLinkSuffix: {
    en: '(Only available in Norwegian)',
    nb: '',
    nn: '',
  },

  // For the message beneath balance

  balanceViewMessage: {
    en: 'All amounts are in Norwegian kroner.',
    nb: 'Alle beløp er i norske kroner.',
    nn: 'Alle beløp er i norske kroner.',
  },

  // for other details

  otherDetailsHeader: {
    en: 'Other details',
    nb: 'Andre detaljer',
    nn: 'Andre detaljar',
  },
  grossPrincipal: {
    en: 'Original guaranteed:',
    nb: 'Opprinnelig garanti:',
    nn: 'Opphavleg garanti:',
  },
  grossPrincipalFooter: {
    en: 'The amount guaranteed may change over time according to agreement.',
    nb: 'Garantert beløp kan endres over tid ifølge avtale.',
    nn: 'Garantert beløp kan endrast over tid ifølgje avtale.',
  },
  startDate: {
    en: 'Valid from:',
    nb: 'Gyldig fra:',
    nn: 'Gyldig frå:',
  },
  adjustedEvery: {
    en: 'Adjusted every:',
    nb: 'Justeres hver:',
    nn: 'Justerast kvar:',
  },
  interestRate: {
    en: 'Current commission:',
    nb: 'Provisjon p.t.:',
    nn: 'Provisjon p.t.:',
  },
  periodLength: {
    en: 'Commision term length:',
    nb: 'Provisjon terminlengde:',
    nn: 'Provisjon terminlengde:',
  },
  counterGuaranteePeriodLength: {
    en: 'Commision term length:',
    nb: 'Provisjon terminlengde:',
    nn: 'Provisjon terminlengde:',
  },
  month: {
    en: 'month',
    nb: 'måned',
    nn: 'månad',
  },
  months: {
    en: 'months',
    nb: 'måneder',
    nn: 'månader',
  },
  percentPerYear: {
    en: '% per year',
    nb: '% per år',
    nn: '% per år',
  },
};
