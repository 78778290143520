/**
 *  Try to parse a JSON string into an object of type T.
 * @param jsonString string to parse
 * @returns an object with success true and the result if parsing was successful, otherwise an object with success false and the error.
 */
export function tryParseJSON<T>(jsonString: string): { success: true; result: T } | { success: false, error: unknown } {
  try {
    const result = JSON.parse(jsonString) as T;
    return { success: true, result };
  } catch (error) {
    return { success: false, error };
  }
}