import { LocalizedString } from '../../utils/localizer';

export const customerTasks: LocalizedString = {
  // Application tasks  
  taskTypeLabelApplication: {
    en: 'Application',
    nb: 'Søknad',
    nn: 'Søknad'
  },
  applicationCardDateLabel: {
    en: 'Submitted',
    nb: 'Sendt inn',
    nn: 'Sendt inn'
  },
  'applicationMessageLabel-Submitted': {
    en: 'Submitted',
    nb: 'Innsendt',
    nn: 'Innsendt'
  },
  'applicationMessageLabel-UnderConsideration': {
    en: 'Under consideration',
    nb: 'Under behandling',
    nn: 'Under behandling'
  },
  'applicationMessageLabel-UnderConsiderationMunicipality': {
    en: 'Under consideration with the municipality',
    nb: 'Under behandling hos kommunen',
    nn: 'Under behandling hos kommunen'
  },
  'applicationMessageLabel-UnderProcessing': {
    en: 'Under consideration',
    nb: 'Under behandling',
    nn: 'Under behandling'
  },
  'applicationMessageLabel-Returned': {
    en: 'Returned',
    nb: 'Returnert',
    nn: 'Returnert'
  },
  'applicationMessageLabel-PendingDecision': {
    en: 'Pending decision',
    nb: 'Til beslutning',
    nn: 'Til beslutning'
  },
  'applicationMessageLabel-MissingInformation': {
    en: 'Pending more information',
    nb: 'Avventer mer informasjon',
    nn: 'Avventer meir informasjon'
  },
  'applicationMessageLabel-Unallocated': {
    en: 'Submitted',
    nb: 'Innsendt',
    nn: 'Innsendt'
  },
  'applicationMessageLabel-UnallocatedMunicipality': {
    en: 'Submitted to municipality',
    nb: 'Innsendt til kommunen',
    nn: 'Innsendt til kommunen'
  },
  'applicationMessageLabel-Approved': {
    en: 'Approved',
    nb: 'Innvilget',
    nn: 'Innvilga'
  },
  'applicationMessageLabel-Withdrawn': {
    en: 'Withdrawn',
    nb: 'Trukket',
    nn: 'Trukket'
  },
  'applicationMessageLabel-Refusal': {
    en: 'Refused',
    nb: 'Avslått',
    nn: 'Avslått'
  },
  'applicationMessageLabel-Discarded': {
    en: 'Discarded',
    nb: 'Forkastet',
    nn: 'Forkasta'
  },
  'applicationMessageLabel-Completed': {
    en: 'Completed',
    nb: 'Ferdig',
    nn: 'Ferdig'
  },
  'applicationMessageLabel-Draft': {
    en: 'Draft',
    nb: 'Kladd',
    nn: 'Kladd'
  },

  // Form tasks
  taskTypeLabelForms: {
    en: 'Change request',
    nb: 'Endringssøknad',
    nn: 'Endringssøknad'
  },
  'taskTypeLabelForms-general-application-additional-information': {
    en: 'Submission of documents',
    nb: 'Innsending av dokumenter',
    nn: 'Innsending av dokumenter'
  },
  formCardDraftDateLabel: {
    en: 'Created',
    nb: 'Opprettet',
    nn: 'Oppretta'
  },
  formCardSubmittedDateLabel: {
    en: 'Submitted',
    nb: 'Sendt inn',
    nn: 'Sendt inn'
  },
  'formMessageLabel-Draft': {
    en: 'Draft',
    nb: 'Kladd',
    nn: 'Kladd'
  },
  'formMessageLabel-Submitted': {
    en: 'Submitted',
    nb: 'Innsendt',
    nn: 'Innsendt'
  },  
  // Inflow tasks
  'Inflow-customer-uploads-documentation': {
    en: 'More information',
    nb: 'Tilleggsopplysninger',
    nn: 'Tilleggsopplysningar'
  },
  'Inflow-customer-uploads-documentation-grant': {
    en: 'More information',
    nb: 'Tilleggsopplysninger',
    nn: 'Tilleggsopplysningar'
  },
  'Inflow-customer-uploads-documentation-loan': {
    en: 'More information',
    nb: 'Tilleggsopplysninger',
    nn: 'Tilleggsopplysningar'
  },
  'Inflow-payout-request-received': {
    en: 'Payout request',
    nb: 'Utbetalingsanmodning',
    nn: 'Utbetalingsanmodning'
  },
  'taskTypeLabelForms-general-application-payout-request': {
    en: 'Submission of documents',
    nb: 'Innsending av dokumenter',
    nn: 'Innsending av dokumenter'
  },
  'taskTypeLabelForms-general-application-additional-information-payout-request': {
    en: 'Submission of documents',
    nb: 'Innsending av dokumenter',
    nn: 'Innsending av dokumenter'
  },
  'Inflow-payout-request-received-loan': {
    en: 'Payout request loan',
    nb: 'Utbetalingsanmodning lån',
    nn: 'Utbetalingsanmodning lån'
  },
  'Inflow-payout-request-received-grant': {
    en: 'Payout request grant',
    nb: 'Utbetalingsanmodning tilskudd',
    nn: 'Utbetalingsanmodning tilskot'
  },
  'Inflow-fill-in-trivial-support-form': {
    en: 'De minimis aid',
    nb: 'Bagatellmessig støtte',
    nn: 'Bagatellmessig støtte'
  },
  'Inflow-fill-in-final-report': {
    en: 'Final report',
    nb: 'Sluttrapport',
    nn: 'Sluttrapport'
  },
  'Inflow-fill-in-insurance-movables': {
    en: 'Insurance movables',
    nb: 'Forsikringsattest',
    nn: 'Forsikringsattest'
  },
  'Inflow-fill-in-self-declaration-form': {
    en: 'Self declaration',
    nb: 'Egenerklæring vekstgaranti',
    nn: 'Egenerklæring vekstgaranti'
  },
  'Inflow-sign-document': {
    en: 'Contract for signing',
    nb: 'Avtale til signering',
    nn: 'Avtale til signering'
  },
  'inflowMessageLabel-pending': {
    en: 'More information',
    nb: 'Avventer mer informasjon',
    nn: 'Avventer mer informasjon'
  },
  'inflowMessageLabel-completed': {
    en: 'Submitted',
    nb: 'Innsendt',
    nn: 'Innsendt'
  },
  'inflowMessageLabel-cancelled': {
    en: 'Cancelled',
    nb: 'Avbrutt',
    nn: 'Avbrutt'
  },
  'inflowMessageLabel-processing': {
    en: 'Processing',
    nb: 'Behandler',
    nn: 'Behandlar'
  }
};
