import React, { FC } from 'react';
import cn from 'classnames';
import './Details.scss';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

export enum HighlightType {
  Inactive,
  Loading,
  Normal,
  Warning,
}

export enum DetailsViewType {
  Amount,
  Date,
  Enum,
  Footer,
  FooterWithLink,
  Header,
  Interest,
  InterestType,
  Link,
  Months,
  PeriodMonths,
  Text,
}

export interface Note {
  invisible?: boolean;
  prefix?: string;
  suffix?: string;
  type?: DetailsViewType;
  value: string;
  warning?: boolean;
}

export interface ViewItem {
  label?: string;
  negative?: boolean;
  notopmargin?: boolean;
  prefix?: string;
  suffix?: string;
  suffixNotTranslated?: boolean;
  type: DetailsViewType;
  value: string;
  invisible?: boolean;
  notes?: Note[];
  key?: string;
}

interface DetailsProps {
  loadingMessage?: string;
  loading?: boolean;
  view: ViewItem[];
}

export const NOT_AVAILABLE = 'n/a';

export const Details: FC<DetailsProps> = (props) => {
  const showDetail = (viewItem: ViewItem | Note) => {
    const prefix = viewItem.prefix ? viewItem.prefix : '';
    const suffix = viewItem.suffix ? viewItem.suffix : '';

    return `${prefix} ${viewItem.value} ${suffix}`.trim();
  };

  const notes = (keyOffset: number, notes: Note[] | undefined): JSX.Element[] => {
    if (notes === undefined) return [];
    return notes
      .filter((note) => note.invisible !== true)
      .map((note, i) => {
        return note.type === DetailsViewType.FooterWithLink ? (
          <div key={-keyOffset * 100 + i} className={cn('detailViewNoFlex noTopMargin footer footerText')}>
            <span className="footerTextPrefix">{note.prefix}</span>&nbsp;
            <a className="link" href={note.value} target="_blank" rel="noopener noreferrer">
              {note.value}
            </a>
            &nbsp;
            {note.suffix}
          </div>
        ) : (
          <div key={-keyOffset * 100 + i} className={cn('detailView noTopMargin footer', { approvedWarning: note.warning })}>
            <div>{showDetail(note)}</div>
          </div>
        );
      });
  };

  const isVisible = (viewItem: ViewItem): boolean => {
    if (viewItem.invisible === true) return false;
    return true;
  };

  if (props.loading === true) return <LoadingSpinner />;

  const block: JSX.Element[] = [];

  props.view.filter(isVisible).forEach((detailViewElement, index) => {
    switch (detailViewElement.type) {
      case DetailsViewType.Amount:
      case DetailsViewType.Enum:
      case DetailsViewType.Interest:
      case DetailsViewType.InterestType:
      case DetailsViewType.Months:
      case DetailsViewType.PeriodMonths:
      case DetailsViewType.Text:
        block.push(
          <div key={index} className="detailView">
            <div>{detailViewElement.label}</div>
            <div className="detailViewValue data-hj-suppress">{showDetail(detailViewElement)}</div>
          </div>
        );
        break;
      case DetailsViewType.Date:
        block.push(
          <div key={index} className="detailView">
            <div className="noWrap">{detailViewElement.label}</div>
            <div className="detailViewValue data-hj-suppress">{showDetail(detailViewElement)}</div>
          </div>
        );
        break;
      case DetailsViewType.Link:
        block.push(
          <div key={index} className="detailView">
            <div>{detailViewElement.label}</div>
            <div className="detailViewValue data-hj-suppress">
              <a className="link" href={'http://' + detailViewElement.value} target="_blank" rel="noopener noreferrer">
                {detailViewElement.value}
              </a>
            </div>
          </div>
        );
        break;
      case DetailsViewType.Header:
        block.push(
          <div key={index} className="detailView noTopMargin header">
            <div>{detailViewElement.value}</div>
          </div>
        );
        break;
      case DetailsViewType.FooterWithLink:
        block.push(
          <div key={index} className={cn('detailViewNoFlex noTopMargin footer footerText')}>
            <span className="footerTextPrefix">{detailViewElement.prefix}</span>&nbsp;
            <a className="link" href={detailViewElement.value} target="_blank" rel="noopener noreferrer">
              {detailViewElement.value}
            </a>
            &nbsp;
            {detailViewElement.suffix}
          </div>
        );
        break;
    }
    block.push(...notes(index, detailViewElement.notes));
  });

  return <>{block}</>;
};
