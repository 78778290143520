import { UserStatus } from '../models';
import { UserDto } from '../models/digibank-mypage.models';
import { ActionMap } from '../types';

export type UserState = {
  currentUser: UserDto;
  status: UserStatus;
};

const initialUser: UserDto = {
  accountPublicId: '',
  displayName: '',
  emailAddress: '',
  companyName: '',
  hasRoles: false
};

export const initialUserState: UserState = {
  currentUser: initialUser,
  status: UserStatus.LOGGED_OUT,
};

export enum UserActionTypes {
  ResetUser = 'RESET_USER',
  SetUser = 'SET_USER',
  SetStatus = 'SET_USER_STATUS'
}

// User

type UserPayload = {
  [UserActionTypes.ResetUser]: undefined;
  [UserActionTypes.SetUser]: UserDto;
  [UserActionTypes.SetStatus]: UserStatus;
};

export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export const userReducer = (state: UserState, action: UserActions) => {
  switch (action.type) {
    case UserActionTypes.SetUser:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UserActionTypes.ResetUser:
      return {
        ...state,
        currentUser: initialUser,
      };
    case UserActionTypes.SetStatus:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};
