import React, { FC, KeyboardEventHandler, useState } from 'react';
import { DocumentDto, DocumentType } from '../../models/digibank-mypage.models';
import { Spinner, FaIcons, FaIcon, EmptyContainer } from '@in/component-library';
import cn from 'classnames';
import './DocumentList.scss';
import { useLocalizer } from '../../hooks';
import { LoadingSpinner } from '../Shared/LoadingSpinner/LoadingSpinner';

interface DocumentListProps {
  documents: DocumentDto[];
  isLoaded: boolean;
  onDownload: (document: DocumentDto) => Promise<void>;
}

interface DocumentListItemProps {
  document: DocumentDto;
  onDownload: () => Promise<void>;
}

const DocumentListItem: FC<DocumentListItemProps> = (props) => {
  const [downloading, setDownloading] = useState<Boolean>(false);

  const downloadFile = () => {
    setDownloading(true);
    props.onDownload().finally(() => setDownloading(false));
  };

  const downloadFileByEnter: KeyboardEventHandler<HTMLLIElement> = (key) => {
    if (key.code === 'Enter') {
      downloadFile();
    }
  };

  return (
    <li
      className={cn('documents downloadLink', { downloading: downloading, read: props.document.userHasRead })}
      onClick={(e) => {
        e.preventDefault();
        downloadFile();
      }}
      onKeyDown={downloadFileByEnter}
      tabIndex={0}
    >
      <div className="left">
        {!props.document.customerHasRead && !props.document.userHasRead ? (
          <FaIcon name={FaIcons.ExclamationCircleLight} size="sm" />
        ) : (
          <FaIcon name={FaIcons.CheckLight} size="sm" />
        )}
      </div>
      <div className="downloadTextWrapper">
        {props.document.documentType === DocumentType.BankingDeferralPaymentDocument && <div className="downloadText">{props.document.name}</div>}
        {props.document.documentType !== DocumentType.BankingDeferralPaymentDocument && (
          <div className="downloadText">{props.document.description ?? props.document.name}</div>
        )}
      </div>
      <div className="right">{downloading ? <Spinner size={'sm'} className="downloadSpinner"></Spinner> : <FaIcon name={FaIcons.ArrowToBottomLight} size="sm" />}</div>
    </li>
  );
};

export const DocumentList: FC<DocumentListProps> = (props: DocumentListProps) => {
  const { l } = useLocalizer('DocumentList');

  if (!props.isLoaded) return <LoadingSpinner />;

  const customerDocuments = props.documents.length ? (
    props.documents.map((document) => <DocumentListItem key={document.id} document={document} onDownload={() => props.onDownload(document)} />)
  ) : (
    <EmptyContainer>{l('noDocumentsLabel')}</EmptyContainer>
  );

  return <ul className="documentList" lang="nb-NO">{customerDocuments}</ul>;
};
