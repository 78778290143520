import React, { FC, ReactElement } from 'react';
import cn from 'classnames';
import './Accordion.scss';

export type AccordionContainerProps = {
  children: React.ReactNode,
  collapse?: boolean
}

export const AccordionContainer: FC<AccordionContainerProps & React.HTMLProps<HTMLUListElement>> = (props) => {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  const collapseProps = (child: ReactElement, index: number) => {
    return { 
      onClick: (event: any) => { 
        setActiveIndex(index);
        child.props.onClick && child.props.onClick(event);
      }, 
      active: activeIndex === index
    } as AccordionProps
  }

  const mappedChildren = React.Children.map(props.children, (child, index) => {
    if (React.isValidElement(child) && props.collapse) {
      return React.cloneElement(child, collapseProps(child, index));
    }

    return child;
  });

  return (
    <ul className="accordion__container" {...props}>
      {mappedChildren}
    </ul>
  );
}

export type AccordionContentProps = {
  active?: boolean;
  children: React.ReactNode
}

export const AccordionContent: FC<AccordionContentProps> = (props) => {
  return (
    <div className={cn('accordion__content', { 'accordion__content--active': props.active })}>
      {props.children}
    </div>
  )
}

export type AccordionProps = {
  onClick?: (event: any) => void;
  title: string
  children: React.ReactNode,
  active?: boolean;
}

export const Accordion: FC<AccordionProps> = (props) => {
  const isActive = (): boolean => {
    return active && (props.active !== undefined ? props.active : true);
  } 

  const [active, setActive] = React.useState(props.active ?? false);
  
  const handleClick = (event: any) => {
    setActive(!isActive());
    props.onClick && props.onClick(event);
  }
  
  return (
    <li className='accordion'>
      <button 
        onClick={handleClick} 
        className={cn('accordion__button', {'accordion__button--active': isActive()})}
      >
        <span className="text">{props.title}</span>
        <div>
          <i className={cn('arrow', {arrow__down: !isActive(), arrow__up: isActive()})}></i>
        </div>
      </button>
      <AccordionContent active={isActive()}>
        {isActive() && props.children}
      </AccordionContent>
    </li>
  )
}

