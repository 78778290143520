import { useEffect } from 'react';
import { useEnvironment } from '../../hooks/useEnvironment';
import useSessionStorage from '../../hooks/useSessionStorage';
import { useUxSignalScript } from '../../hooks/useUxSignalScript';
import { tryParseJSON } from '../../utils/tryParseJSON';

import './FeedbackPrompt.css';

const uxSignalsEvents = {
  isDisqualified: 'isDisqualified',
  closeWidget: 'closeWidget',
  bookingCompleted: 'bookingCompleted',
} as const;

export const studyIds = {
  startPage: 'xkf8r58rs3',
  projectAccountingFormReceipt: '8h3kdycifh',
} as const;

type FeedBackPromptProps = {
  studyId: keyof typeof studyIds;
  firstButtonBlue?: boolean;
};

export default function FeedBackPrompt({ studyId, firstButtonBlue }: FeedBackPromptProps) {
  // TODO needs unique identifier, can probably reuse the guid from ux-signal...
  const [shouldShowFeedbackPrompt, setShouldShowFeedbackPrompt] = useSessionStorage<boolean>(`shouldShowFeedbackPrompt-${studyIds[studyId]}`, true);

  useUxSignalScript(shouldShowFeedbackPrompt);
  const { isProd } = useEnvironment();

  const demoMode = !isProd() ? 'demo' : '';

  useEffect(() => {
    function handleMessage(e: MessageEvent) {
      if (typeof e.data !== 'string') return;

      const parsedData = tryParseJSON<{ command: keyof typeof uxSignalsEvents }>(e.data);
      if (!parsedData.success) return;

      if (parsedData.result.command === uxSignalsEvents.isDisqualified || parsedData.result.command === uxSignalsEvents.bookingCompleted) {
        setShouldShowFeedbackPrompt(false);
      }
    }

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!shouldShowFeedbackPrompt) return null;

  return <div className={firstButtonBlue ? 'first-button-blue' : ''} data-uxsignals-embed={`panel-${studyIds[studyId]}`} data-uxsignals-mode={demoMode} />;
}
