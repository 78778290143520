export const customerFormActions = {
  formActionsTitle: {
    en: 'Apply for a change',
    nb: 'Søk om endring',
    nn: 'Søk om endring',
  },
  formActionApplicationMaintenanceAmortizationDelay: {
    en: 'Installment free period',
    nb: 'Avdragsutsettelse',
    nn: 'Avdragsutsetjing',
  },
  // If there are no loans to apply for an installment free period we show this message as well
  formActionApplicationMaintenanceAmortizationDelayDisabled: {
    en: 'You have no loan on which you can apply for an installment free period.',
    nb: 'Du har ingen lån du kan søke om avdragsutsettelse på.',
    nn: 'Du har ingen lån du kan søke om avdragsutsetjing på.',
  },
  formActionDeferralPayment: {
    en: 'Deferral of payment up to 3 months.',
    nb: 'Betalingsutsettelse inntil 3 måneder',
    nn: 'Betalingsutsettelse inntil 3 måneder',
  },
  formActionWhatElse: {
    en: 'What can be changed?',
    nb: 'Hva annet kan endres?',
    nn: 'kva anna kan endrast?',
  },
  formActionComplaint: {
    en: 'Appeal a decision',
    nb: 'Klage på vedtak',
    nn: 'Klage på vedtak',
  },
  buttonLabel: {
    en: 'Next',
    nb: 'Neste',
    nn: 'Neste',
  },
} as const;
