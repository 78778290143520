export const invoicesLocalization = {
  header: {
    en: 'Invoice overview',
    nb: 'Fakturaoversikt',
    nn: 'Fakturaoversikt',
  },
  dueDate: {
    en: 'Due date',
    nb: 'Betalingsfrist',
    nn: 'Betalingsfrist',
  },
  totalAmount: {
    en: 'Amount due',
    nb: 'Beløp å betale',
    nn: 'Beløp å betale',
  },
  originalAmount: {
    en: 'Original amount',
    nb: 'Opprinnelig beløp',
    nn: 'Opphavleg beløp',
  },
  paymentAccountNumber: {
    en: 'Payable to account',
    nb: 'Betales til konto',
    nn: 'Betalast til konto',
  },
  customerIdentification: {
    en: 'KID-number',
    nb: 'KID-nummer',
    nn: 'KID-nummer',
  },
  invoiceNumber: {
    en: 'Invoice',
    nb: 'Faktura',
    nn: 'Faktura',
  },
  originalDueDate: {
    en: 'Due date',
    nb: 'Forfallsdato',
    nn: 'Forfallsdato',
  },
  finalMessage: {
    en: 'After the due date, penalty interest accrues on unpaid invoices, even if the payment deadline has been extended.',
    nb: 'Etter forfallsdato påløper forsinkelsesrenter på ubetalte fakturaer, selv om betalingsfristen er forlenget.',
    nn: 'Etter forfallsdato kjem forseinkingsrenter på ubetalte fakturaer, sjølv om betalingsfristen er forlengt.',
  },
  directDebitMessage: {
    en: 'We offer direct debit. Contact your bank to establish an agreement. If an EHF agreement is in place, notices will automatically be sent as an EHF.',
    nb: 'Ønsker du avtalegiro? Ta kontakt med din bank for å opprette avtale. Dersom EHF-avtale er etablert, vil terminvarsler automatisk bli sendt som EHF',
    nn: 'Ønskjer du avtalegiro? Ta kontakt med din bank for å opprette avtale. Dersom EHF avtale er etablert, vil terminvarsler automatisk bli sendt som EHF.',
  },
} as const;
