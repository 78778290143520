import { DateTime } from 'luxon';
import { FC } from 'react';
import { useDateUtils, useLocalizer } from '../../hooks';
import { ErrorCard } from './CustomerTaskCardTypes/ErrorCard';
import { InformationCard } from './CustomerTaskCardTypes/InformationCard';
import { SuccessCard } from './CustomerTaskCardTypes/SuccessCard';
import { CustomerTaskListItemProps } from './CustomerTaskListItemProps';

export const CustomerTaskInflowCard: FC<CustomerTaskListItemProps> = (props) => {
  const { l } = useLocalizer('CustomerTasks');
  const { toFormattedCoreViewDate } = useDateUtils();

  const typeLabel = props.task.data !== undefined ? props.task.data['taskType'] : props.task.type;

  const genericCardTitle = l(`${props.task.type}-${typeLabel}${props.task.data?.accountType ? `-${props.task.data.accountType}` : ''}`) + ` ${props.task.data?.taskTypeDescription}`;
  const signDocumentCardTitle = l('Inflow-sign-document');

  const Card = () => {
    const cardProps = {
      headerText: l(`inflowMessageLabel-${props.task.status}`),
      message: props.task.message as string,
      title: typeLabel == 'sign-document' ? signDocumentCardTitle : genericCardTitle,
      type: props.task.title as string,
      date: `${props.task.active ? l('formCardDraftDateLabel') : l('formCardSubmittedDateLabel')}: ${toFormattedCoreViewDate(props.task.created, DateTime.DATE_SHORT)}`,
    };

    switch (props.task.status) {
      case 'cancelled':
        return <ErrorCard {...cardProps} />;
      case 'completed':
        return <SuccessCard {...cardProps} />;
      default:
        return <InformationCard {...cardProps} />;
    }
  };

  return <Card />;
};
