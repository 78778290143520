import { LocalizedString } from '../../utils/localizer';

export const customerTasksList: LocalizedString = {
  noTasksLabel: {
    en: 'No pending tasks',
    nb: 'Ingen nye oppgaver',
    nn: 'Ingen nye oppgaver'
  },
  historyLabel: {
    en: 'History',
    nb: 'Historikk',
    nn: 'Historikk',
  }
};
