import { EmptyContainer } from '@in/component-library';
import cn from 'classnames';
import { FC } from 'react';
import { useLocalizer } from '../../hooks';
import { CustomerTaskDto, CustomerTaskTypeEnum } from '../../models/digibank-mypage.models';
import { CustomerTaskApplicationCard } from '../CustomerTaskCard/CustomerTaskApplicationCard';
import { CustomerTaskConferenceGrantCard } from '../CustomerTaskCard/CustomerTaskConferenceGrantCard';
import { CustomerTaskFormCard } from '../CustomerTaskCard/CustomerTaskFormCard';
import { CustomerTaskInflowCard } from '../CustomerTaskCard/CustomerTaskInflowCard';
import { CustomerTaskListItemProps } from '../CustomerTaskCard/CustomerTaskListItemProps';
import { Accordion, AccordionContainer } from '../Shared/Accordion/Accordion';
import { LoadingSpinner } from '../Shared/LoadingSpinner/LoadingSpinner';
import './CustomerTaskList.scss';

interface CustomerTaskListProps {
  tasks: Array<CustomerTaskDto>;
  isLoaded: boolean;
  onTaskClick: (task: CustomerTaskDto) => Promise<void>;
}

const CustomerTaskListItem: FC<CustomerTaskListItemProps> = (props) => {
  const Card = () => {
    switch (props.task.type) {
      case CustomerTaskTypeEnum.Application:
        return <CustomerTaskApplicationCard {...props} />;
      case CustomerTaskTypeEnum.Forms:
        return <CustomerTaskFormCard {...props} />;
      case CustomerTaskTypeEnum.Inflow:
        return <CustomerTaskInflowCard {...props} />;
      case CustomerTaskTypeEnum.ConferenceGrant:
        // If it is a conference grant in draft form, it shall be displayed as an application
        return <CustomerTaskConferenceGrantCard {...props} />;
      default:
        console.warn('Unknown task type: ', props.task.type);
        return <CustomerTaskInflowCard {...props} />; // Fallback
    }
  };

  return Card ? (
    <li className="customerTaskListItem">
      <button
        className={cn({ disabled: props.disabled })}
        onMouseDown={() => {
          props.onClick();
        }}
      >
        <Card />
      </button>
    </li>
  ) : (
    <li>
      <></>
    </li>
  );
};

export const CustomerTaskList: FC<CustomerTaskListProps> = (props) => {
  const { l } = useLocalizer('CustomerTasksList');

  const activeTasks = props.tasks.filter((t) => t.active);
  const inActiveTasks = props.tasks.filter((t) => !t.active);

  if (!props.isLoaded) return <LoadingSpinner />;

  return (
    <div className="customerTaskList" lang="nb-NO">
      {activeTasks.length ? (
        <ol className="taskList">
          {props.tasks
            .filter((t) => t.active)
            .map((task) => (
              <CustomerTaskListItem key={task.id} task={task} onClick={() => props.onTaskClick(task)} />
            ))}
        </ol>
      ) : (
        <EmptyContainer>{l('noTasksLabel')}</EmptyContainer>
      )}
      {inActiveTasks.length ? (
        <AccordionContainer>
          <Accordion title={l('historyLabel')} active>
            <ol className="taskList">
              {props.tasks
                .filter((t) => !t.active)
                .map((task) => (
                  <CustomerTaskListItem key={task.id} task={task} onClick={() => props.onTaskClick(task)} />
                ))}
            </ol>
          </Accordion>
        </AccordionContainer>
      ) : undefined}
    </div>
  );
};
