import { NOT_AVAILABLE } from '../components/Shared/Details/Details';

export function addThousandSeparators(number: any, decimalSeparator?: string) {
  var parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  let result = '';
  if (decimalSeparator !== undefined) {
    result = parts.join(decimalSeparator);
  } else {
    result = parts.join(',');
  }
  return result;
}

export function getFixedDisplayValue(value: string, decimals: number, decimalSeparator?: string): string | undefined {
  if (value === undefined || value === null) return NOT_AVAILABLE;
  let val: string = value.toString();
  val = val.replace(',', '.');
  val = val.replaceAll(' ', '');
  const num: number = +val;
  let decimalAmount = num.toFixed(decimals);
  if (decimalAmount !== undefined) {
    return addThousandSeparators(decimalAmount, decimalSeparator);
  }
  return undefined;
}

export const amountExists = (amount: number | undefined): boolean => {
  if (amount === undefined || amount === null || amount === 0) {
    return false;
  }
  return true;
};
