import { BankAccountCategoryEnum, CustomerFormAction, FormActionDto, FormApplicationTypeEnum } from '../models/digibank-mypage.models';
import { apiDispatcher } from '../utils';
import { FormApplicationTypeDto } from './../models/digibank-mypage.models';

const baseUrl = '/api/v1/formActions';

export const getBankAccountFormActions = (accountCategory: BankAccountCategoryEnum, accountName: string) => {
  return apiDispatcher<Array<FormActionDto>>(`${baseUrl}/GetBankAccountFormActions/${accountCategory}/${accountName}`, 'GET');
};

export const getProjectAccountingFormActions = (accountCategory: BankAccountCategoryEnum, accountName: string) => {
  return apiDispatcher<Array<FormActionDto>>(`${baseUrl}/GetProjectAccountingFormActions/${accountCategory}/${accountName}`, 'GET');
};

export const getApplicationTaskFormAction = (applicationId: string, taskId: string) => {
  return apiDispatcher<FormActionDto>(`${baseUrl}/GetApplicationTaskFormAction/${applicationId}/${taskId}`, 'GET');
};

export const getCustomerFormActions = () => {
  return apiDispatcher<CustomerFormAction[]>(`${baseUrl}/GetCustomerFormActions`, 'GET');
};

export const getApplicationFormAction = (identifier: string, showSubmitted: boolean) => {
  return apiDispatcher<FormActionDto>(`${baseUrl}/GetApplicationFormAction/${identifier}?showSubmitted=${showSubmitted}`, 'GET');
};

export const getFormApplicationTypes = () => {
  return apiDispatcher<FormApplicationTypeDto[]>(`${baseUrl}/GetFormApplicationTypes`, 'GET');
};

export const getFormAction = (formActionTypeEnum: FormApplicationTypeEnum, caseNumber: string, accountName?: string) => {
  if (accountName === undefined || accountName === null || accountName === '') {
    return apiDispatcher<any>(`${baseUrl}/GetFormAction/${formActionTypeEnum}/${caseNumber}`, 'GET');
  }
  return apiDispatcher<any>(`${baseUrl}/GetFormAction/${formActionTypeEnum}/${caseNumber}/${accountName}`, 'GET');
};

export const deleteAllStartedFormsAndGetFormAction = (formActionTypeEnum: FormApplicationTypeEnum, caseNumber: string, accountName?: string) => {
  if (accountName === undefined || accountName === null || accountName === '') {
    return apiDispatcher<any>(`${baseUrl}/DeleteAllStartedFormsAndGetFormAction/${formActionTypeEnum}/${caseNumber}`, 'GET');
  }
  return apiDispatcher<any>(`${baseUrl}/DeleteAllStartedFormsAndGetFormAction/${formActionTypeEnum}/${caseNumber}/${accountName}`, 'GET');
};
