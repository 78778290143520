import React, { useContext } from 'react';
import { AppContext } from './app-context';
import { localizer } from '../utils/localizer';

export interface LocalizeOptions {
    capitalize?: boolean;
}

const LocalizationContext = React.createContext<{ (namespace: string, key: string, options?: LocalizeOptions): string }>((key) => "");

const LocalizationProvider = (props: { children: React.ReactNode }) => {
    const {state} = useContext(AppContext);

    const localize = (namespace: string, key: string, options?: LocalizeOptions):string => {      
        return localizer(namespace, state.configuration.language, key, options);
    }
    
    document.documentElement.lang = state.configuration.language;

    return (
        <LocalizationContext.Provider value={localize}>
            {props.children}
        </LocalizationContext.Provider>
    )
}

export { LocalizationContext, LocalizationProvider };
