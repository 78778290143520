import { Language } from '../models';
import { FeatureDto, HeaderDto, MessageDto, NavItemDto } from '../models/digibank-mypage.models';
import { ActionMap } from '../types';
import { Locale, languages } from '../utils/locales';

export type ConfigurationState = {
  navItems: NavItemDto[];
  messages: MessageDto[];
  features: FeatureDto[];
  header: HeaderDto;
  language: Language;
  locale?: Locale;
};

export const initialConfigurationState: ConfigurationState = {
  navItems: [],
  messages: [],
  features: [],
  header: {} as HeaderDto,
  language: Language.nb
};

export enum ConfigurationActionTypes {
  SetNavItems = 'SET_NAVITEMS',
  SetHeader = 'SET_HEADER',
  SetLanguage = 'SET_LANGUAGE',
  SetMessages = 'SET_MESSAGES',
  SetFeatures = 'SET_FEATURES'
}

// Configuration

type ConfigurationPayload = {
  [ConfigurationActionTypes.SetNavItems]: NavItemDto[];
  [ConfigurationActionTypes.SetHeader]: HeaderDto;
  [ConfigurationActionTypes.SetLanguage]: Language;
  [ConfigurationActionTypes.SetMessages]: MessageDto[];
  [ConfigurationActionTypes.SetFeatures]: FeatureDto[];
};

export type ConfigurationActions = ActionMap<ConfigurationPayload>[keyof ActionMap<ConfigurationPayload>];

export const configurationReducer = (state: ConfigurationState, action: ConfigurationActions) => {
  switch (action.type) {
    case ConfigurationActionTypes.SetNavItems:
      return {
        ...state,
        navItems: action.payload,
      };
    case ConfigurationActionTypes.SetLanguage:
      return {
        ...state,
        language: action.payload,
        locale: languages.get(action.payload),
      };
    case ConfigurationActionTypes.SetMessages:
      return {
        ...state,
        messages: action.payload
      }
    case ConfigurationActionTypes.SetHeader:
      return {
        ...state,
        header: action.payload
      }
    case ConfigurationActionTypes.SetFeatures:
      return {
        ...state,
        features: action.payload
      }
    default:
      return state;
  }
};
