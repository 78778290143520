import { LocalizedString } from '../../utils/localizer';

export const interestSubsidyDetails: LocalizedString = {
  // Title

  title: {
    en: 'Interest subsidy',
    nb: 'Rentestøtte',
    nn: 'Rentestønad',
  },
  subTitle: {
    en: 'Reference',
    nb: 'Referanse',
    nn: 'Referanse',
  },

  // For detailsView

  header: {
    en: 'Interest subsidy',
    nb: 'Rentestøtte',
    nn: 'Rentestønad',
  },
  availableAmount: {
    en: 'Basis for calculation:',
    nb: 'Beregningsgrunnlag:',
    nn: 'Beregningsgrunnlag:',
  },
  nextPayoutDate: {
    en: 'Next payout date:',
    nb: 'Neste utbetalingsdato:',
    nn: 'Neste utbetalingsdato:',
  },

  // message

  balanceViewMessage: {
    en: 'All amounts are in Norwegian kroner.',
    nb: 'Alle beløp er i norske kroner.',
    nn: 'Alle beløp er i norske kroner.',
  },

  // For validityView

  detailsHeader: {
    en: 'Other details',
    nb: 'Andre detaljer',
    nn: 'Andre detaljar',
  },
  approveDate: {
    en: 'Date granted:',
    nb: 'Innvilget dato:',
    nn: 'Innvilga dato:',
  },
  totalAmount: {
    en: 'Amount granted:',
    nb: 'Innvilget beløp:',
    nn: 'Innvilga beløp:',
  },
  payMethod: {
    en: 'Type of loan:',
    nb: 'Lånetype:',
    nn: 'Lånetype:'
  },
  payoutAmount: {
    en: 'Amount disbursed:',
    nb: 'Utbetalt beløp:',
    nn: 'Utbetalt beløp:'
  },
  interestRate: {
    en: 'Current nominal interest rate:',
    nb: 'Nominell rente p.t.:',
    nn: 'Nominell rente p.t.:',
  },
  percentPerYear: {
    en: '% per year',
    nb: '% per år',
    nn: '% per år',
  },
  matureDate: {
    en: 'Final payment:',
    nb: 'Siste utbetaling:',
    nn: 'Siste utbetaling:',
  },

  // For code

  unknownDate: {
    en: 'date not specified',
    nb: 'dato ikke angitt',
    nn: 'dato ikkje gjeve opp',
  },
  transactionOverviewTitle: {
    en: 'Recent transactions',
    nb: 'Siste bevegelser',
    nn: 'Siste bevegelser',
  }
};
