import { DateTime } from 'luxon';
import { FC } from 'react';
import { useDateUtils, useLocalizer } from '../../hooks';
import { InformationCard } from './CustomerTaskCardTypes/InformationCard';
import { CustomerTaskListItemProps } from './CustomerTaskListItemProps';

export const CustomerTaskConferenceGrantCard: FC<CustomerTaskListItemProps> = (props) => {
  const { l } = useLocalizer('CustomerTasks');
  const { toFormattedCoreViewDate } = useDateUtils();

  return (
    <InformationCard
      headerText={l(`applicationMessageLabel-${props.task.status}`)}
      type={l(`taskTypeLabelApplication`)}
      title={props.task.title as string}
      message={props.task.message as string}
      date={`${l('applicationCardDateLabel')}: ${toFormattedCoreViewDate(props.task.created, DateTime.DATE_SHORT)}`}
    ></InformationCard>
  );
};
