import { useContext } from 'react';
import { AppContext } from '../context/app-context';

export const useFeatures = () => {
  const { state } = useContext(AppContext);

  const hasFeature = (name: string) => {
    const feature = state.configuration.features.find(f => f.name === name);

    return feature && feature.isActive
  }
  
  return {
    hasFeature
  }
}
