import { Card, Icon, Icons } from '@in/component-library';
import { FC, ReactNode, useState } from 'react';
import './CustomerTaskCardBase.scss';

export type CustomerTaskCardProps = {
  children?: ReactNode;
  headerText: string;
  borderColor: 'blue' | 'gray' | 'red' | 'green' | 'black';
  headerColor: 'blue' | 'yellow' | 'green' | 'red' | 'gray';
  icon?: 'success' | 'info' | 'error';
  noArrow?: boolean;
};

/**
 * Base card for Customer Task cards
 */
export const CustomerTaskCardBase: FC<CustomerTaskCardProps> = (props) => {
  const HeaderIcon = () => {
    switch (props.icon) {
      case 'info':
        return <InfoOrErrorIcon type="info" />;
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <InfoOrErrorIcon type="error" />;
      default:
        return <></>;
    }
  };

  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <Card className={`customerTaskCardBase ${props.borderColor}`} boxShadow={true}>
      <div className="cardGrid" onClick={() => setShowSpinner(true)}>
        <div className={`header ${props.headerColor}`}>
          <HeaderIcon />
          {props.headerText}
        </div>
        <div className="content">
          <div>{props.children}</div>
        </div>
        {!props.noArrow && <div className="arrow">{showSpinner ? <Icon size="small" name={Icons.Spinner} inline /> : <Icon size="small" name={Icons.ArrowRight} inline />}</div>}
      </div>
    </Card>
  );
};

// Icons
// Taken from the deprecated component in IN-component library. They are placed here as we expect the cards to get a facelift in
// the near future

const InfoOrErrorIcon = ({ type }: { type: 'info' | 'error' }) => {
  return (
    <div className={`icon ${type}`}>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="info"
        className="svg-inline--fa fa-info fa-xs "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192 512"
        color="white"
      >
        <path
          fill="currentColor"
          d="M144 80c0 26.5-21.5 48-48 48s-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z"
        ></path>
      </svg>
    </div>
  );
};

const SuccessIcon = () => {
  return (
    <div className="success icon">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="check"
        className="svg-inline--fa fa-check fa-xs "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        color="white"
      >
        <path
          fill="currentColor"
          d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
        ></path>
      </svg>{' '}
    </div>
  );
};
