import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalizer } from '../hooks';
import { Feature } from './Feature';
import FeedBackPrompt from './FeedbackPrompt/FeedbackPrompt';
import { TabDefinition, Tabs } from './Shared/Tabs/Tabs';

import { useEffect, useState } from 'react';
import { ShowUxSignalsFeedbackPrompt_StartPage } from '../constants/featureFlags';
import './NavMenu.scss';

export const NavMenu = () => {
  const { l, globalL } = useLocalizer('Navigation');
  const [showNavMenu, setShowNavMenu] = useState<boolean>(false);
  const [showNavMenuIndex, setShowNavMenuIndex] = useState<number>(0);

  const location = useLocation();
  const navigate = useNavigate();

  const mainNavMenu = ['/bankaccounts', '/casemanagement', '/documents'];

  const tabs: TabDefinition[] = [
    {
      id: 0,
      label: l('bankAccounts'),
      title: l('bankAccounts'),
    },
    {
      id: 1,
      label: l('caseManagement'),
      title: l('caseManagement'),
    },
    {
      id: 2,
      label: l('documents'),
      title: l('documents'),
    },
  ];

  useEffect(() => {
    setShowNavMenu(mainNavMenu.find((l) => l === location.pathname) !== undefined);
    setShowNavMenuIndex(mainNavMenu.findIndex((l) => l === location.pathname));
  }, [location.pathname]);

  const handleTabOnSelect = (n: number): void => {
    navigate(mainNavMenu[n]);
  };

  return showNavMenu ? (
    <>
      <h1 className="mt-[10px]">{globalL('pageTitle')}</h1>

      <Feature name={ShowUxSignalsFeedbackPrompt_StartPage}>
        <FeedBackPrompt studyId="startPage" firstButtonBlue />
      </Feature>

      <Tabs aria-label={l('menuAriaLabel')} tabs={tabs} handleTabOnSelect={(i) => handleTabOnSelect(i)} overrideSelection={showNavMenuIndex ?? 0}></Tabs>
    </>
  ) : (
    <></>
  );
};
