import { LocalizedString } from '../../utils/localizer';

export const breadCrumbs: LocalizedString = {
  bankaccounts: {
    en: 'Bank accounts',
    nb: 'Bankkontoer',
    nn: 'Bankkontoar',
  },
  bankaccount: {
    en: 'Bank account',
    nb: 'Bankkonto',
    nn: 'Bankkonto',
  },
  loan: {
    en: 'Loan',
    nb: 'Lån',
    nn: 'Lån',
  },
  grant: {
    en: 'Grant',
    nb: 'Tilskudd',
    nn: 'Tilskot',
  },
  backingGuarantee: {
    en: 'Backing guarantee',
    nb: 'Vekstgaranti',
    nn: 'Vekstgaranti',
  },
  interestsubsidy: {
    en: "Interest subsidy",
    nb: "Rentestøtte",
    nn: "Rentestønad",
  },
  casemanagement: {
    en: "Case Management",
    nb: "Saksbehandling",
    nn: "Saksbehandling",
  },
  documents: {
    en: "Documents",
    nb: "Dokumenter",
    nn: "Dokumenter",
  },
  forms: {
    en: "Forms",
    nb: "Skjema",
    nn: "Skjema",
  },
  submitted: {
    en: "Submitted",
    nb: "Innsendt",
    nn: "Innsend",
  },
  applications: {
    en: "Applications",
    nb: "Søknader",
    nn: "Søknadar",
  }
};
