import React, { FC, useContext } from 'react';
import { Footer as BaseFooter } from '@in/component-library';
import envelope from '../assets/icons/envelope.svg';
import phone from '../assets/icons/phone.svg';
import { useLocalizer, useResponsiveView } from '../hooks';
import './Footer.scss';
import { AppContext } from '../context/app-context';

export const Footer: FC = () => {
  const { l } = useLocalizer('Footer');
  const { width } = useResponsiveView();
  const { state } = useContext(AppContext);
  return (
    <BaseFooter>
      <p>
        {l('questionPart1')}
        <br></br>
        {l('questionPart2')} <strong>{l('questionPart3')}</strong> {l('questionPart4')} <strong>{l('questionPart5')}</strong> {l('questionPart6')}
      </p>
      <div className="footer-contacts">
        <div>
          <a target="_blank" href={width > 770 ? 'callto:4722002500' : 'tel:4722002500'} className={width > 770 ? 'disabled-link' : undefined} rel="noopener noreferrer">
            <div className="footer-iconRow footer-link noBreak">
              <img className="footer-icon-small" src={phone} alt="phone number" width="20" height="20" />
              <div>(+47) 22 00 25 00</div>
            </div>
          </a>
        </div>
        <div>
          <a target="_blank" href="mailto:kundesupport@innovasjonnorge.no" rel="noopener noreferrer">
            <div className="footer-iconRow footer-link">
              <img className="footer-icon" src={envelope} alt="e-mail" width="25" height="25" />
              <div>kundesupport@innovasjonnorge.no</div>
            </div>
          </a>
          <div className="footer-iconRow footer-link footer-icon-empty">
            {state.configuration?.language === 'nn' ? (
              <a href="https://uustatus.no/nn/erklaringer/publisert/29970c7e-6d74-4326-88dc-73557455bfc1" rel="nofollow noreferrer noopener" target="_blank">
                Tilgjengelegheitserklæring
              </a>
            ) : (
              <a href="https://uustatus.no/nb/erklaringer/publisert/29970c7e-6d74-4326-88dc-73557455bfc1" rel="nofollow noreferrer noopener" target="_blank">
                Tilgjengelighetserklæring
              </a>
            )}
          </div>
        </div>
      </div>
    </BaseFooter>
  );
};
