import { FC } from 'react';
import { CustomerTaskCardBase } from './CustomerTaskCardBase';

interface InformationCardProps {
  type: string;
  message: string | undefined;
  headerText: string;
  date: string;
  title: string;
}

export const InformationCard: FC<InformationCardProps> = ({ type, message, headerText, date, title }) => {
  return (
    <CustomerTaskCardBase borderColor="blue" headerText={headerText} headerColor="blue" icon="info">
      <div className="type">{type}</div>
      <div className="title">{title}</div>
      <div className="message">{message}</div>
      <div className="date">{date}</div>
    </CustomerTaskCardBase>
  );
};
