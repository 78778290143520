import { DateTime } from 'luxon';
import { FC } from 'react';
import { useDateUtils, useLocalizer } from '../../hooks';
import { ErrorCard } from './CustomerTaskCardTypes/ErrorCard';
import { GenericCard } from './CustomerTaskCardTypes/GenericCard';
import { InformationCard } from './CustomerTaskCardTypes/InformationCard';
import { SuccessCard } from './CustomerTaskCardTypes/SuccessCard';
import { CustomerTaskListItemProps } from './CustomerTaskListItemProps';

export const CustomerTaskApplicationCard: FC<CustomerTaskListItemProps> = (props) => {
  const { l } = useLocalizer('CustomerTasks');
  const { toFormattedCoreViewDate } = useDateUtils();

  const Card = () => {
    const cardProps = {
      headerText: l(`applicationMessageLabel-${props.task.status}`),
      type: l(`taskTypeLabel${props.task.type}`),
      title: props.task.title as string,
      message: props.task.message as string,
      date: `${l('applicationCardDateLabel')}: ${toFormattedCoreViewDate(props.task.created, DateTime.DATE_SHORT)}`,
    };

    switch (props.task.status) {
      case 'MissingInformation':
        return <InformationCard {...cardProps} />;
      case 'Approved':
        return <SuccessCard {...cardProps} />;
      case 'Refusal':
        return <ErrorCard {...cardProps} />;
      default:
        return <GenericCard {...cardProps} />;
    }
  };

  return <Card />;
};
