import { LocalizedString } from '../../utils/localizer';

export const applicationDetails: LocalizedString = {
  referenceNoLabel: {
    en: 'Reference',
    nb: 'Referanse',
    nn: 'Referanse'
  },
  documentTabLabel: {
    en: 'Documents',
    nb: 'Dokumenter',
    nn: 'Dokumenter'
  },
  taskTabLabel: {
    en: 'Tasks',
    nb: 'Oppgaver',
    nn: 'Oppgaver'
  },
  historyTabLabel: {
    en: 'History',
    nb: 'Historikk',
    nn: 'Historikk'
  },
  historyTitle: {
    en: 'Your application',
    nb: 'Din søknad',
    nn: 'Din søknad'
  },
  linkToCaseLeadText: {
    en: 'Here you will find the submitted application. The application is locked.',
    nb: 'Her finner du innsendt søknad, den er låst for endringer.',
    nn: 'Her finn du innsend søknad, den er låst for endringar.'
  },
  linkToCaseLinkText: {
    en: 'Show application',
    nb: 'Vis søknad',
    nn: 'Vis søknad'
  },
  menuAriaLabel: {
    en: 'Application details menu',
    nb: 'Søknadsdetaljer meny',
    nn: 'Søknadsdetaljar meny'
  }
};
