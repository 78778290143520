export interface Locale {
  decimalSeparator: string;
  locale: string;
}

// For setting dates and figures without looking at navigator settings
export const languages: Map<string, Locale> = new Map();
languages.set("en", { decimalSeparator: ".", locale: "en-GB" });
languages.set("nb", { decimalSeparator: ",", locale: "nb-NO" });
languages.set("nn", { decimalSeparator: ",", locale: "nn-NO" });
