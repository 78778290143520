import React, { FC, useEffect, useState } from 'react';
import useGlobalKeyHandler from './../../../hooks/useGlobalKeyHandler';
import './Tabs.scss';
import classNames from 'classnames';

export interface TabDefinition {
  id: number;
  label: string;
  title: string;
}

export interface TabProps {
  tabs: TabDefinition[];
  'aria-label'?: string;
  /* default selected when not touched */
  defaultSelection?: number;
  /* override selected. Useful when jumping between routes */
  overrideSelection?: number;
  handleTabOnSelect: (n: number) => void;
}

const Tabs: FC<TabProps> = (props) => {
  const [selected, setSelected] = useState<number | undefined>();
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    if (props.overrideSelection !== undefined) {
      props.handleTabOnSelect(props.overrideSelection);
      setSelected(props.overrideSelection);
    } else if (props.defaultSelection !== undefined && !touched) {
      props.handleTabOnSelect(props.defaultSelection);
      setSelected(props.defaultSelection);
    }
  }, [props.overrideSelection]);

  const updateSelected = (n: number) => {
    setSelected(n);
    props.handleTabOnSelect(n);
    setTouched(true);
  };

  const handleKeyDownGlobal = (e: KeyboardEvent) => {
    setTimeout(function () {
      const focusedElement = document.activeElement;
      if (focusedElement instanceof HTMLElement && focusedElement.dataset.tabId) {
        const n = Number(focusedElement.dataset.tabId);
        if (e.key === 'Enter' || e.key === 'Space') {
          updateSelected(n);
        } else if (e.key === 'ArrowRight') {
          const focus = n < props.tabs.length - 1 ? n + 1 : n;
          if (focus > n) {
            const nextMenuElement = focusedElement.parentElement?.parentElement?.nextElementSibling?.firstChild?.firstChild;
            nextMenuElement && (nextMenuElement as HTMLElement).focus();
          }
        } else if (e.key === 'ArrowLeft') {
          const focus = n > 0 ? n - 1 : n;
          if (focus < n) {
            const previousMenuElement = focusedElement.parentElement?.parentElement?.previousElementSibling?.firstChild?.firstChild;
            previousMenuElement && (previousMenuElement as HTMLElement).focus();
          }
        }
      }
    }, 0);
  };
  useGlobalKeyHandler(handleKeyDownGlobal);

  const handleMouse = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    updateSelected(index);
  };

  return (
    <div>
      {
        <div className="localTabs">
          <nav aria-label={props['aria-label']}>
            <ul className="tabMenu">
              {props.tabs.map((t, i) => {
                return (
                  <li key={i}>
                    <div className="labelWrapper">
                      <div
                        className={classNames('px-0 py-1 border-solid border-0 border-b-4 focus:border-b-color-blue-600', { 'border-b-color-blue-600': selected === i }, { 'border-b-in-white': selected !== i })}
                        data-tab-id={i}
                        onClick={(_) => handleMouse(_, i)}
                        title={t.title}
                        tabIndex={0}
                      >
                        {t.label}
                      </div>
                      <div className="labelSpace"></div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      }
    </div>
  );
};

export { Tabs };
