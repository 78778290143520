import { LocalizedString } from '../../utils/localizer';

export const footer: LocalizedString = {
  questionPart1: {
    en: 'Questions about your accounts?',
    nb: 'Spørsmål om dine kontoer?',
    nn: 'Spørsmål om kontoane dine?',
  },
  questionPart2: {
    en: 'Provide',
    nb: 'Oppgi',
    nn: 'Gje opp',
  },
  questionPart3: {
    en: 'customer name',
    nb: 'kundenavn',
    nn: 'kundenamn',
  },
  questionPart4: {
    en: 'and',
    nb: 'og',
    nn: 'og',
  },
  questionPart5: {
    en: 'referance number',
    nb: 'referansenummer',
    nn: 'referansenummer',
  },
  questionPart6: {
    en: 'on contact:',
    nb: 'ved kontakt:',
    nn: 'ved kontakt:',
  },
}
