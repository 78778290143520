import { LocalizedString } from '../../utils/localizer';

export const collaterals: LocalizedString = {
  header: {
    en: 'Involved',
    nb: 'Involverte',
    nn: 'Involverte',
  },
  customerId: {
    en: 'Organization number',
    nb: 'Organisasjonsnummer',
    nn: 'Organisasjonsnummer',
  },
  customerType: {
    en: 'Customer type',
    nb: 'Kundetype',
    nn: 'Kundetype',
  },
  type: {
    en: 'Role in the agreement',
    nb: 'Rolle i avtalen',
    nn: 'Rolle i avtalen',
  },
  name: {
    en: 'Name',
    nb: 'Navn',
    nn: 'Namn',
  },
  company: {
    en: 'Company',
    nb: 'Firma',
    nn: 'Firma',
  },
  person: {
    en: 'Person',
    nb: 'Person',
    nn: 'Person',
  },

  debtor: {
    en: 'Debtor',
    nb: 'Låntaker',
    nn: 'Låntakar',
  },
  coDebtor: {
    en: 'Co-debtor',
    nb: 'Medlåntaker',
    nn: 'Medlåntakar',
  },
  guarantor: {
    en: 'Guarantor',
    nb: 'Kausjonist',
    nn: 'Kausjonist',
  },
  realSurety: {
    en: 'Real surety',
    nb: 'Realkausjonist',
    nn: 'Realkausjonist',
  },
};
