import { Language } from "../models"
import { localizations } from '../assets/localizations';

export interface Localizations {
    [namespace: string]: LocalizedString
}

export interface LocalizedString {
    [key: string]: { 
        en?: string;
        nb?: string;
        nn?: string;
    }
}

interface LocalizeOptions {
    capitalize?: boolean;
}

export const localizer = (namespace: string, lang: Language, key: string, options?: LocalizeOptions):string => {
    if (localizations[namespace] === undefined) {
        console.debug(`Namespace '${namespace}' not defined in localizations`);
        return key;
    }
    
    const localization = localizations[namespace][key];

    if (localization === undefined) {
        console.debug(`Localization in namespace '${namespace}' with key '${key}' not defined in localizations`);
        return key;
    }

    let value = localization[lang];

    if (!value && value !== '') {
        console.debug(`Localization [${namespace}][${key}][${lang}] not defined in localizations`);
        return key;
    }

    if (options?.capitalize) {
        value = value.charAt(0).toUpperCase() + value.slice(1)
    }

    return value;
}
