import { LocalizedString } from '../../utils/localizer';

export const paymentPlan: LocalizedString = {
  // For headers

  dueDate: {
    en: 'Due date',
    nb: 'Betalingsfrist',
    nn: 'Betalingsfrist',
  },
  dueYear: {
    en: 'Year',
    nb: 'År',
    nn: 'År',
  },
  interest: {
    en: 'Interest',
    nb: 'Renter',
    nn: 'Renter',
  },
  amortization: {
    en: 'Installment',
    nb: 'Avdrag',
    nn: 'Avdrag',
  },
  termFee: {
    en: 'Fee',
    nb: 'Gebyr',
    nn: 'Gebyr',
  },
  termFeeTitle: {
    en: 'Invoice fee',
    nb: 'Termingebyr',
    nn: 'Termingebyr',
  },
  totalYearFee: {
    en: 'Fee',
    nb: 'Gebyr',
    nn: 'Gebyr',
  },
  totalAmount: {
    en: 'Amount',
    nb: 'Beløp',
    nn: 'Beløp',
  },
  totalAmountTitle: {
    en: 'Payment amount',
    nb: 'Terminbeløp',
    nn: 'Terminbeløp',
  },
  totalYearAmount: {
    en: 'Amount',
    nb: 'Årsbeløp',
    nn: 'Årsbeløp',
  },
  totalYearAmountTitle: {
    en: 'Annual amount',
    nb: 'Årsbeløp',
    nn: 'Årsbeløp',
  },
  endBalance: {
    en: 'Balance',
    nb: 'Saldo',
    nn: 'Saldo',
  },

  // For footnote

  footnote: {
    en: 'The amounts may vary depending on the development of interest or other costs. The repayment plan will be updated after each disburse.',
    nb: 'Beløpene kan variere med utvikling av renter eller andre omkostninger. Nedbetalingsplanen blir oppdatert ved hver utbetaling.',
    nn: 'Beløpa kan variere om renta eller andre kostnader endrar seg. Nedbetalingsplanen blir oppdatert ved kvar utbetaling.',
  },

  // For paymentplan

  paymentPlan: {
    en: 'Payment plan',
    nb: 'Nedbetalingsplan',
    nn: 'Nedbetalingsplan',
  },
  paymentPlanEmpty: {
    en: 'The loan has no future installments.',
    nb: 'Lånet har ingen fremtidige terminer.',
    nn: 'Lånet har ingen framtidige terminar.',
  },
  downloadLinkText: {
    en: 'Payment plan',
    nb: 'Nedbetalingsplan',
    nn: 'Nedbetalingsplan',
  },
};
