import { LocalizedString } from "../../utils/localizer";

export const taskCard: LocalizedString = {
  // Task types
  'customer-uploads-documentation': {
    nb: 'Tilleggsopplysninger er nødvendig for videre saksbehandling',
    nn: 'Tilleggsopplysningar er nødvendig for vidare saksbehandling',
    en: 'Additional information is necessary for further case handling'
  },

  // Task status
  'pending': {
    nb: 'Avventer svar',
    nn: 'Avventer svar',
    en: 'Pending'
  },
  'cancelled': {
    nb: 'Kansellert',
    nn: 'Kansellert',
    en: 'Cancelled'
  },
  'completed': {
    nb: 'Besvart',
    nn: 'Besvart',
    en: 'Completed'
  },
  'processing': {
    nb: 'Behandles',
    nn: 'Behandles',
    en: 'Processing'
  },
  'lastChangedLabel': {
    nb: 'Sist endret',
    nn: 'Sist endret',
    en: 'Last changed'
  }
}
