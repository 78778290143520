import React, { createContext, useReducer, Dispatch, useState } from "react";
import { HeaderDto, Language, UserStatus } from "../models";
import { userReducer, UserActions, UserActionTypes, UserState, initialUserState } from "./user-reducer";
import { ConfigurationActions, ConfigurationActionTypes, configurationReducer, ConfigurationState, initialConfigurationState } from './configuration-reducer';
import { ApplicationActions, applicationReducer, ApplicationState, initialApplicationState } from './app-reducer';
import * as sessionService from '../services/session.service';

type AppState = {
    user: UserState,
    configuration: ConfigurationState,
    application: ApplicationState
};

const initialState: AppState = {
    user: initialUserState,
    configuration: initialConfigurationState,
    application: initialApplicationState
};

const AppContext = createContext<{
    state: AppState;
    dispatch: Dispatch<UserActions | ConfigurationActions | ApplicationActions>;
  }>({
    state: initialState,
    dispatch: () => null
  });

const appReducer = ({ user, configuration, application }: AppState, action: UserActions | ConfigurationActions | ApplicationActions) => ({
    user: userReducer(user, action as UserActions),
    configuration: configurationReducer(configuration, action as ConfigurationActions),
    application: applicationReducer(application, action as ApplicationActions)
});

const AppProvider = (props: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(appReducer, initialState);
    const [localLanguage, setLocalLanguage] = useState(initialState.configuration.language);
    const [isInitialized, setIsInitialized] = useState(false);

    const initContext = async () => {
        try {
            const sessionConfig = await sessionService.getCurrentConfiguration();

            if (sessionConfig.user === null) {
                window.location.replace("/login");
            }

            else if (sessionConfig.user !== undefined && sessionConfig.user !== null) {
                dispatch({ type: UserActionTypes.SetStatus, payload: UserStatus.LOGGED_IN });
                dispatch({ type: UserActionTypes.SetUser, payload: sessionConfig.user });
            }

            dispatch({ type: ConfigurationActionTypes.SetLanguage, payload: (sessionConfig.language ?? "nb") as Language });
            dispatch({ type: ConfigurationActionTypes.SetNavItems, payload: sessionConfig.navItems ?? [] });
            dispatch({type: ConfigurationActionTypes.SetHeader, payload: sessionConfig.header ?? {} as HeaderDto})
            dispatch({ type: ConfigurationActionTypes.SetMessages, payload: sessionConfig.messages ?? [] });
            dispatch({ type: ConfigurationActionTypes.SetFeatures, payload: sessionConfig.features ?? [] });

            setIsInitialized(true);
        }
        catch(error) {
            console.error(error);   
            
            window.location.pathname = "/login"
        }
    }

    React.useEffect(() => {
        if (state.user.status === UserStatus.LOGGED_OUT
            || localLanguage !== state.configuration.language) {
            initContext();
            setLocalLanguage(state.configuration.language);
        }
    }, [state.user.status, state.configuration.language, localLanguage]);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {isInitialized &&
                props.children
            }
        </AppContext.Provider>
    )
}

export { AppProvider, AppContext };
