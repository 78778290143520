import { useNavigate } from 'react-router-dom';
import { createForm } from '../services/forms.service';
import { FormActionDto, FormActionTypeEnum } from '../models';

export const useFormActionHandler = () => {
  const navigate = useNavigate();

  return async (formAction: FormActionDto, replace: boolean = false) => {
    switch (formAction.actionType) {
      case FormActionTypeEnum.OpenForm:
        navigate(`/forms/edit/${formAction.data}/0`, { replace: replace });
        break;
      case FormActionTypeEnum.CreateForm:
        const formId = await createForm(formAction.applicationType, formAction.data);
        navigate(`/forms/edit/${formId}/0`, { replace: replace });
        break;
      case FormActionTypeEnum.ShowSubmitted:
        navigate(`/forms/submitted/${formAction.data}`, { replace: replace });
        break;
      case FormActionTypeEnum.OpusRedirect:
        window.open(formAction.data as string, '_blank');
        break;
      case FormActionTypeEnum.CaseRedirect:
        window.location.replace(formAction.data as string);
        break;
    }
  };
};
