import { useEffect } from 'react';

function useGlobalKeyHandler(keyDownHandler?: (e: KeyboardEvent) => void) {
  useEffect(() => {
    if (keyDownHandler) {
      document.addEventListener('keydown', keyDownHandler);
    }

    return () => {
      if (keyDownHandler) {
        document.removeEventListener('keydown', keyDownHandler);
      }
    };
  }, [keyDownHandler]);
}

export default useGlobalKeyHandler;
