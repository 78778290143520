import { FC } from 'react';
import { Route, Routes } from 'react-router';
import { ApplicationDetailsData } from './ApplicationDetailsController';

export const Applications: FC = () => {
  return (
    <Routes>
      <Route path=":applicationId" element={<ApplicationDetailsData />} />
    </Routes>
  );
};
