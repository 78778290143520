import { useContext } from 'react';
import { LocalizationContext, LocalizeOptions } from '../context/localization-context';

// TODO we can add type safety on nameSpace as well, by refactoring to inject the object as type T instead of string
const useLocalizer = (namespace: string) => {
  const ctx = useContext(LocalizationContext);

  if (!ctx) {
    throw new Error('useLocalizer must be used within a LocalizationProvider');
  }

  const l = (key: string, options?: LocalizeOptions) => ctx(namespace, key, options);

  /**
   * Type safe version of l using template literal keys of types. Object type T has to be an object literal, suffixed with "as const" assertion. See localizationsLoanDetails.ts for example.
   * @param key A template literal key existing of type T
   * @usage typeSafeL<typeof LoanDetails>("formActionsTitle")
   */
  const typeSafeL = <T>(key: keyof T, options?: LocalizeOptions): string => l(key as string, options);

  const L = (key: string) => l(key, { capitalize: true });

  const globalL = (key: string, options?: LocalizeOptions) => ctx('Global', key, options);

  return { l, L, globalL, typeSafeL };
};

export { useLocalizer };

// Retroactive types to prevent regressions. We cannot use interface due to existing code.
// TODO swap out with interfaces
type useLocalizerReturnType = ReturnType<typeof useLocalizer>;
export type localizerFunction =  useLocalizerReturnType['l']; 
