import { useContext } from 'react';
import { AppContext } from '../context/app-context';
import { formatCoreviewDate, getCoreviewYear } from '../utils/dates';

const useDateUtils = () => {
  const { state } = useContext(AppContext);

  const toFormattedCoreViewDate = (value: any, formatOption?: Intl.DateTimeFormatOptions): string => {
    return formatCoreviewDate(value, state.configuration.locale?.locale ?? 'nb-NO', formatOption);
  };

  const toCoreViewYear = (value: any): string => {
    return getCoreviewYear(value, state.configuration.locale?.locale ?? 'nb-NO');
  };

  return {
    toFormattedCoreViewDate,
    toCoreViewYear,
  };
};

export { useDateUtils };

// Retroactive types to prevent regressions. We cannot use interface due to existing code.
// TODO swap out with interfaces
type useDateUtilsReturnType = ReturnType<typeof useDateUtils>;
export type toFormattedCoreViewDateFunction = useDateUtilsReturnType['toFormattedCoreViewDate'];
