import { FC } from 'react';

import { ServiceMessage } from '../../models/service-message';

import { Notification } from '@in/component-library';

interface ServiceMessageListProps {
  messages: ServiceMessage[];
}

const ServiceMessageList: FC<ServiceMessageListProps> = ({ messages }) => (
  <div className="flex flex-col gap-8 mb-8 service-message-list">
    {messages.map((message) => (
      <Notification className="service-message-list__message" key={message.header} type={message.type} fullWidth>
        <h2>{message.header}</h2>
        <div dangerouslySetInnerHTML={{ __html: message.body }} />
      </Notification>
    ))}
  </div>
);

export default ServiceMessageList;
