import { DateTime } from 'luxon';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/app-context';
import { useDateUtils, useLocalizer } from '../../hooks';
import { InformationCard } from './CustomerTaskCardTypes/InformationCard';
import { SuccessCard } from './CustomerTaskCardTypes/SuccessCard';
import { CustomerTaskListItemProps } from './CustomerTaskListItemProps';

export const CustomerTaskFormCard: FC<CustomerTaskListItemProps> = (props) => {
  const { l } = useLocalizer('CustomerTasks');
  const { toFormattedCoreViewDate } = useDateUtils();
  const { state } = useContext(AppContext);

  const [formIdentifier, setFormIdentifier] = useState<string>();
  const [taskTypeLabelForms, setTaskTypeLabelForms] = useState<string>();

  useEffect(() => {
    setFormIdentifier(props.task.data.formApplicationType ?? undefined);
  }, [props.task]);

  useEffect(() => {
    if (!formIdentifier) return;
    const identifier = props.task.data?.formApplicationType ?? '';
    if (identifier === 'general-application-additional-information') {
      setTaskTypeLabelForms(l('taskTypeLabelForms-general-application-additional-information'));
    } else if (identifier === 'general-application-payout-request') {
      setTaskTypeLabelForms(l('taskTypeLabelForms-general-application-payout-request'));
    } else if (identifier === 'general-application-additional-information-payout-request') {
      setTaskTypeLabelForms(l('taskTypeLabelForms-general-application-additional-information-payout-request'));
    } else {
      setTaskTypeLabelForms(l('taskTypeLabelForms'));
    }
  }, [formIdentifier, state.configuration.locale?.locale]);

  const Card = () => {
    const cardProps = {
      headerText: l(`applicationMessageLabel-${props.task.status}`),
      type: taskTypeLabelForms ?? '',
      title: props.task.title as string,
      message: props.task.message as string,
      date: `${props.task.active ? l('formCardDraftDateLabel') : l('formCardSubmittedDateLabel')}: ${toFormattedCoreViewDate(props.task.created, DateTime.DATE_SHORT)}`,
    };

    switch (props.task.status) {
      case 'Draft':
        return <InformationCard {...cardProps} />;
      case 'Submitted':
        return <SuccessCard {...cardProps} />;
      default:
        return <InformationCard {...cardProps} />;
    }
  };

  return <Card />;
};
